import { put, take } from 'redux-saga/effects';
import { ORDER_FINISHED } from '../actions/orderFinished';
import errorAction from '../actions/error';
import routerHistory from '../constants/routerHistory';

export default function* selectPlan() {
  while (true) {
    try {
      yield take(ORDER_FINISHED);
      routerHistory.push('/step-3');

    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error fetching IP address geo location',
      }));
    }
  }
}
