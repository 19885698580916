import { SET_SERVICE_LEVELS } from '../actions/serviceLevels';

export default function serviceLevels(
  state = { applicableDiscounts: [], billingAmount: 0, discountAmount: 0 },
  action
) {
  switch (action.type) {
    case SET_SERVICE_LEVELS:
      return action.payload;
    default:
      return state;
  }
}
