import { SET_EXPERIMENT } from '../actions/experiments';

export default function experiments(state = '', action) {
  switch (action.type) {
    case SET_EXPERIMENT:
      return action.payload;
    default:
      return state;
  }
}
