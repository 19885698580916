import { put } from 'redux-saga/effects';
import setGeoLocation from '../actions/setGeoLocation';
import isClient from '../constants/isClient';
import requestJSONP from '../utils/requestJSONP';

const freeGeoIPJSONURL = process.env.FREE_GEO_IP_JSON_URL || 'https://api.ipstack.com/check?access_key=566719b19a463dd643e82d2301e777d3&output=json&legacy=1';

export default function* fetchGeoLocationSaga() {
  if (!isClient) {
    return;
  }
  try {
    const defaultLocationData = {};
    defaultLocationData.country_code = process.env.REACT_APP_DEFAULT_COUNTRY_CODE || 'US';
    yield put(setGeoLocation(defaultLocationData));

    const geoLocationData = yield requestJSONP(freeGeoIPJSONURL);
    // Override For India
    if(geoLocationData && geoLocationData.country_code && geoLocationData.country_code === 'IN'){
      geoLocationData.country_code = 'US';
    }
    yield put(setGeoLocation(geoLocationData));
  } catch (error) {
    const geoLocationData = {};
    geoLocationData.country_code = process.env.REACT_APP_DEFAULT_COUNTRY_CODE || 'US';
    yield put(setGeoLocation(geoLocationData));
  }
}
