/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
import { take } from 'redux-saga/effects';
import isClient from '../constants/isClient';

export default function* logActionsSaga() {
  if (!isClient) {
    return;
  }
  if (process.env.REACT_APP_ENABLE_LOGGING === 'true') {
    while (true) {
      const action = yield take();
      console.group(action.type);
      console.info('dispatching', action);
      console.groupEnd(action.type);
    }
  }
}
