import { createAction } from 'redux-actions';

export const PAYMENT_REQUESTING = 'PAYMENT_REQUESTING';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

export const paymentRequesting = createAction(PAYMENT_REQUESTING);
export const paymentError = createAction(PAYMENT_ERROR);
export const paymentSuccess = createAction(PAYMENT_SUCCESS);

export const PAYPAL_REQUESTING = 'PAYPAL_REQUESTING';
export const PAYPAL_ERROR = 'PAYPAL_ERROR';
export const PAYPAL_SUCCESS = 'PAYPAL_SUCCESS';

export const paypalRequesting = createAction(PAYPAL_REQUESTING);
export const paypalError = createAction(PAYPAL_ERROR);
export const paypalSuccess = createAction(PAYPAL_SUCCESS);

export const GOOGLE_PAY_REQUESTING = 'GOOGLE_PAY_REQUESTING';
export const GOOGLE_PAY_ERROR = 'GOOGLE_PAY_ERROR';
export const GOOGLE_PAY_SUCCESS = 'GOOGLE_PAY_SUCCESS';

export const googlePayRequesting = createAction(GOOGLE_PAY_REQUESTING);
export const googlePayError = createAction(GOOGLE_PAY_ERROR);
export const googlePaySuccess = createAction(GOOGLE_PAY_SUCCESS);

export const APPLE_PAY_REQUESTING = 'APPLE_PAY_REQUESTING';
export const APPLE_PAY_ERROR = 'APPLE_PAY_ERROR';
export const APPLE_PAY_SUCCESS = 'APPLE_PAY_SUCCESS';

export const applePayRequesting = createAction(APPLE_PAY_REQUESTING);
export const applePayError = createAction(APPLE_PAY_ERROR);
export const applePaySuccess = createAction(APPLE_PAY_SUCCESS);