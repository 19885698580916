import { SET_SUB_EXPERIMENT } from '../actions/experiments';

export default function subExperiment(state = '', action) {
  switch (action.type) {
    case SET_SUB_EXPERIMENT:
      return action.payload;
    default:
      return state;
  }
}
