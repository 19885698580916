export const GRADE_K_5 = 'K - 5';
export const GRADE_6_8 = '6 - 8';
export const GRADE_9_12 = '9 - 12';


export const GRADE_K = 'K';
export const GRADE_1 = '1';
export const GRADE_2 = '2';
export const GRADE_3 = '3';
export const GRADE_4 = '4';
export const GRADE_5 = '5';
export const GRADE_6 = '6';
export const GRADE_7 = '7';
export const GRADE_8 = '8';
export const GRADE_al1 = 'al1';
export const GRADE_geo = 'gem';


export function range(grade) {
  if (grade && typeof grade  !== 'string') {
    grade = grade.toString();
  }
  let range = GRADE_K_5;
  if (grade && (grade === '0' || grade.toLowerCase() === 'k' || grade === '1' || grade === '2' || grade === '3' || grade === '4' || grade === '5')) {
    range = GRADE_K_5;
  } else if (grade && (grade === '6' || grade === '7' || grade === '8')) {
    range = GRADE_6_8;
  } else if (grade && (grade.toLowerCase() === 'gem' || grade === '10')) {
    range = 'Geometry';
  } else if (grade && (grade === '9' || grade.toLowerCase() === 'al1')) {
    range = "Algebra 1"
  }

  return range;
}

export function label(grade) {
  if (grade && typeof grade  !== 'string') {
    grade = grade.toString();
  }
  if (grade && (grade === '1' || grade === '2' || grade === '3' || grade === '4' || grade === '5' || grade === '6' || grade === '7' || grade === '8')) {
    return `Grade ${grade}`;
  } else if (grade && (grade === '0' || grade.toLowerCase() === 'k')) {
    return 'Kindergarten';
  } else if (grade && (grade === '9' || grade.toLowerCase() === 'al1' || grade.toLowerCase() === 'algebra 1')) {
    return `Algebra 1`;
  } else if (grade && (grade === '10' || grade.toLowerCase() === 'gem' || grade.toLowerCase() === 'geometry')) {
    return `Geometry`;
  }
}

export function rangeLabel(gradeRange) {
  if (gradeRange && gradeRange === GRADE_9_12) {
    return 'Algebra 1'
  }
  return gradeRange;
}