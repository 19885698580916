/* global Raven */
import isClient from '../constants/isClient';

const sentryDSN = process.env.SENTRY_DSN || 'https://fd935d49c7a94ed7a2e2e23ece890bbf@sentry.io/113532';

export default function configureRavenSaga() {
  if (!isClient) {
    return;
  }

  if (typeof Raven !== 'undefined') {
    Raven
      .config(sentryDSN)
      .install();
  }
}
