const initalState = {
  billingCycle: 1,
  totalBeforeDiscount: 0,
  payNowDiscount: 0,
  savings: 0,
  totalDiscount: 0,
  totalAfterDiscount: 0,
};

const totals = (
  state = {
    monthlyTotal: initalState,
    quarterlyTotal: { ...initalState, billingCycle: 3 },
    halfYearlyTotal: { ...initalState, billingCycle: 6 },
    yearlyTotal: { ...initalState, billingCycle: 12 },
  },
  action
) => {
  switch (action.type) {
    case "TOTALS_UPDATED":
      return action.payload;

    default:
      return state;
  }
};

export default totals;
