import { select, take, put } from 'redux-saga/effects';
import axios from 'axios';
import errorAction from '../actions/error';
import loadingAction from '../actions/loading';
import setBraintreeClientToken from '../actions/setBraintreeClientToken';
import selectSession from '../selectors/session';
import { SET_PASSWORD } from '../actions/setPassword';
import selectParentInfo from '../selectors/parentInfo';
import selectParentInformationFormValues from '../selectors/parentInformationFormValues';
import selectGeoLocation from '../selectors/geoLocation';
import routerHistory from '../constants/routerHistory';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';

export default function* setPasswordSaga() {
  while (true) {
    yield take(SET_PASSWORD);
    const parentInformationFormValues = yield select(selectParentInformationFormValues);

    const geoLocation = yield select(selectGeoLocation);
    const session = yield select(selectSession);
    const parentInfo = yield select(selectParentInfo);
    try {
      yield put(loadingAction(true));
      const createAccountResponse = yield axios({
        method: 'put',
        baseURL,
        headers: { Authorization: `JWT ${session.token}` },
        url: `/v2/parents/${session.userId}`,
        data: {
          first_name: parentInfo.first_name,
          password: parentInformationFormValues.password,
          country: geoLocation.country_code || 'US',
        },
      });
      const createAccountResult = createAccountResponse.data;

      // if (typeof _paq !== 'undefined') {
      //   _paq.push(['setUserId', createAccountResult.id]);
      // }

      if (window && window.dataLayer) {
        // NOTE: window.dataLayer is an observed array that must be mutated...
        window.dataLayer.push({
          event: 'authentication',
          authType: 'Sign Up',
          uid: createAccountResult.id,
        });
      }

      // Fetch braintree client token
      const braintreeClientTokenResponse = yield axios({
        method: 'post',
        baseURL,
        url: '/v1/paymentmethods/client_token',
        headers: { Authorization: `JWT ${session.token}` },
        data: { userId: session.userId },
      });
      const braintreeClientTokenResult = braintreeClientTokenResponse.data;
      const braintreeClientToken = braintreeClientTokenResult.clientToken;
      yield put(loadingAction(false));
      // Set app braintree client token...
      yield put(setBraintreeClientToken(braintreeClientToken));

      routerHistory.push({
        pathname: '/step-2',
        search: window.location.search,
      });
    } catch (error) {
      yield put(loadingAction(false));
      yield put(errorAction({
        error,
        title: 'Error submitting student information form',
      }));
    }
  }
}