import axios from 'axios';
import {
  select,
  take,
  put,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { create as callbackCreateBraintreeClient } from 'braintree-web/client';
import { ENROLL } from '../actions/enroll';
import loadingAction from '../actions/loading';
import selectBraintreeClientToken from '../selectors/braintreeClientToken';
import selectPaymentInformationFormValues from '../selectors/paymentInformationFormValues';
import selectSession from '../selectors/session';
import selectSelectedPlans from '../selectors/selectedPlans';
import selectSelectedService from '../selectors/selectedService';
import selectSelectedBillingCycle from '../selectors/selectedBillingCycle';
import selectPlans from '../selectors/plans';
import selectStudents from '../selectors/students';
import selectTotals from '../selectors/totals';
import selectParent from '../selectors/parentInfo';
import routerHistory from '../constants/routerHistory';
import selectCart from '../selectors/cart';
import selectEnrollCouponCode from '../selectors/enrollCouponCode'
import studentInfo from '../selectors/studentInfo';
import * as PaymentActions from '../actions/payment';
import setBraintreeClientToken from "../actions/setBraintreeClientToken";
import analytics from '../actions/analytics';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';

const paymentFormName = 'PaymentInformationForm';

async function createTicket(data, sessionToken) {
  return new Promise(async(resolve, reject) => {
    try {
      await axios({
        method: 'post',
        baseURL,
        url: 'v1/zendesk/ticket',
        headers: { Authorization: `JWT ${sessionToken}` },
        data,
      });
      return resolve()
    } catch(error) {
      let tagError = {
        ...error,
        tag: 'createTicket'
      }
      return reject(tagError);
    }
  });
}

async function createBraintreeClient(braintreeClientToken, sessionUserId, sessionToken) {
  return new Promise(async(resolve, reject) => {
    callbackCreateBraintreeClient({
      authorization: braintreeClientToken,
    }, async(error, client) => {
      if (error) {
        console.log(error, 'Error in createBraintreeClient')
        error = {
          ...error,
          tag: 'createBraintreeClient'
        }
        const data = {
          subject: 'Registration flow - Payment failed Notification!!!',
          body: `User ID: ${sessionUserId}\n` +
                `error: ${JSON.stringify(error)}\n`,
          user: sessionUserId,
          email: 'info@hellothinkster.com',
          error: JSON.stringify(error),
          cc: ['karthik@hellothinkster.com', 'kiran@hellothinkster.com', 'nikhil@hellothinkster.com', 'akhilahegde@hellothinkster.com'],
        };
        await createTicket(data, sessionToken);
        return reject(error);
      }
      return resolve(client);
    });
  });
}

async function sendBraintreeRequest(braintreeClient, braintreeRequestBody, sessionUserId, sessionToken) {
  return new Promise(async(resolve, reject) => {
    braintreeClient.request({
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: braintreeRequestBody,
    }, async(error, response) => {
      if (error) {
        console.log(error, 'Error in sendBraintreeRequest')
        error = {
          ...error,
          tag: 'sendBraintreeRequest'
        }
        const data = {
          subject: 'Registration flow - Payment failed Notification!!!',
          body: `User ID: ${sessionUserId}\n` +
                `error: ${JSON.stringify(error)}\n`,
          user: sessionUserId,
          email: 'info@hellothinkster.com',
          error: JSON.stringify(error),
          cc: ['karthik@hellothinkster.com', 'kiran@hellothinkster.com', 'nikhil@hellothinkster.com', 'akhilahegde@hellothinkster.com'],
        };
        await createTicket(data, sessionToken)
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}

function pushBillingCycleToDataLayer(cart) {
  window.dataLayer.push({
    event: 'chooseBillingCycle',
    billingCycle: `${cart.billingCycle} month`, // selected billing cycle
  });
}

function pushPurchaseToDataLayer(cart, selectedPlans, plans, sessionUserId, selectedBillingCycle) {
  const products = [];
  selectedPlans.map((selectedPlan) => {
    const { selectedGradeLevel, selectedService } = selectedPlan;
    products.push({
      name: plans[selectedGradeLevel][selectedService][1].product_name,
      price: parseFloat(plans[selectedGradeLevel][selectedService][1].amount).toFixed(2).toString(),
      category: plans[selectedGradeLevel][selectedService][1].cf_service_id,
      quantity: 1,
    });
    return null;
  });
  products.push({
    name: 'Pay Today discount',
    price: `-${cart.payNowDiscount}`,      // It is a negative value
    category: 'Discounts',
    quantity: 1,            // It is always 1
  });
  window.dataLayer.push({
    event: 'purchase',
    billingCycle: `${cart.billingCycle} month`,      // Billing Cycle
    ecommerce: {
      purchase: {
        actionField: {
          id: sessionUserId,           // Transaction ID is required.
          affiliation: 'Hello Thinkster',
          revenue: cart.totalAfterDiscount,     // Final transaction value after discount
          tax: '0.00',             // Leave 0.00
          shipping: '0.00',       // Leave 0.00
          coupon: '',    // Leave empty if not promo code
        },
        products,
      },
    },
  });
}

export default function* submitPaymentInformationFormSaga() {
  while (true) {
    const action = yield take(ENROLL);
    try {
      const cart = yield select(selectCart);
      pushBillingCycleToDataLayer(cart);

      const selectedPlans = yield select(selectSelectedPlans);
      const plans = yield select(selectPlans);
      const selectedService = yield select(selectSelectedService);
      const selectedBillingCycle = yield select(selectSelectedBillingCycle);
      const session = yield select(selectSession);
      const students = yield select(selectStudents);
      const totals = yield select(selectTotals);
      const sessionToken = session.token;
      const sessionUserId = session.userId;
      const payment_method =  action.payload && action.payload.type ? action.payload.type : 'card';
      const couponCode = yield select(selectEnrollCouponCode);
      let service = selectedService && selectedService[0].selectedService ? selectedService[0].selectedService : 'Gold';
      let paymentMethodsResponse = null;
      const braintreeClientToken = yield select(selectBraintreeClientToken);
      const paymentInformationFormValues = yield select(selectPaymentInformationFormValues);
      const creditCardValues = paymentInformationFormValues.creditCard || {};
      const billingAddressValues = creditCardValues.billingAddress || {};

      const braintreeRequestBody = {
        creditCard: {
          number: creditCardValues.number,
          cvv: creditCardValues.cvv,
          expirationDate: `${creditCardValues.expirationMonth}/${creditCardValues.expirationYear}`,
          billingAddress: {
            firstName: billingAddressValues.firstName,
            lastName: billingAddressValues.lastName,
            streetAddress: billingAddressValues.streetAddress,
            locality: billingAddressValues.city,
            postalCode: billingAddressValues.postalCode,
            countryCodeAlpha2: creditCardValues.countryCode
          },
        },
      };
      
      yield put(loadingAction(true));
      yield put(PaymentActions.paymentRequesting());
      yield put(startSubmit(paymentFormName));
      const braintreeClient = yield createBraintreeClient(braintreeClientToken, sessionUserId, sessionToken);
      const braintreeResponse = yield sendBraintreeRequest(braintreeClient, braintreeRequestBody, sessionUserId, sessionToken);
      yield put(PaymentActions.paymentSuccess(braintreeResponse.creditCards));
      const creditCards = braintreeResponse.creditCards || [];
      const creditCard = creditCards[0] || {};

      const savedStudents = yield select(studentInfo);
      let studentIds = [];
      savedStudents.map((student) => {
        studentIds.push(student.id)
        return null;
      });

      // Create payment method...
      paymentMethodsResponse = yield axios({
        method: 'post',
        baseURL,
        url: '/v1/paymentmethods',
        headers: { Authorization: `JWT ${sessionToken}` },
        data: {
          nonceFromTheClient: creditCard.nonce,
          userId: sessionUserId,
          studentIds: studentIds,
          app: "enroll",
          payment_method: payment_method
        },
      });

      if (paymentMethodsResponse && paymentMethodsResponse.data.paymentMethod.token) {
        yield axios({
          method: 'post',
          baseURL: cbBaseURL,
          url: '/create_payment_method',
          data: {
            customer_id: sessionUserId,
            token: paymentMethodsResponse.data.paymentMethod.token,
            type: creditCardValues.number ? 'card' : 'paypal',
            coupon_code: couponCode ? couponCode.code : ''
          },
        });
      }

      yield put(stopSubmit(paymentFormName));
      yield put(loadingAction(false));

      try {
        //Pushing total checkout amount - this is passed to facebook as customer value
        if (totals) {
          var totalCheckoutAmount = 0;
          if (selectedBillingCycle.toString() === '12' && totals.annualTotal) {
            totalCheckoutAmount = totals.annualTotal.totalBeforeDiscount;
          } else if (selectedBillingCycle.toString() === '6' && totals.semiAnnualTotal) {
            totalCheckoutAmount = totals.semiAnnualTotal.totalBeforeDiscount;
          } else if (selectedBillingCycle.toString() === '3' && totals.quaterlyTotal) {
            totalCheckoutAmount = totals.quaterlyTotal.totalBeforeDiscount;
          } else if (totals.monthlyTotal) {
            totalCheckoutAmount = totals.monthlyTotal.totalBeforeDiscount;
          }
          window.dataLayer.push({ total_checkout_amount: totalCheckoutAmount });
        }

        window.dataLayer.push({
          event: 'payment_completed',
          sessionUserId,
          students,
          selectedPlans,
          selectedBillingCycle,
        });
        pushPurchaseToDataLayer(cart, selectedPlans, plans, sessionUserId, selectedBillingCycle);
      } catch(err) {
        // Skip
      }
      yield put(analytics({
        user_address_street: billingAddressValues.streetAddress,
        user_address_city: billingAddressValues.city,
        user_address_region: billingAddressValues.city,
        user_address_country: creditCardValues.countryCode,
        user_address_postal_code: billingAddressValues.postalCode,
      }));

      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitSuccessful');
        }
      } catch (error) {
        // ...
      }
      // Check if Registration Flow is present
      let path = '/thank-you-web-sa';
      if (service === 'Lite'){
        path = '/thank-you-lite';
      } else if (service === 'GuidedLearning') {
        path = '/guided-learning-purchase';
      }
      routerHistory.push({
        pathname: path,
        search: window.location.search,
      });
    } catch (error) {
      const session = yield select(selectSession);
      const sessionToken = session.token;
      const sessionUserId = session.userId;
      const parent = yield select(selectParent);

      let parentEmail;
      if (parent && parent.email_address) {
        parentEmail = parent.email_address;
      }
      const data = {
        subject: 'Registration flow - Payment failed Notification!!!',
        body: `User ID: ${sessionUserId}\n` +
              `User Email-ID: ${parentEmail}\n` +
              `error: ${JSON.stringify(error)}\n`,
        user: sessionUserId,
        email: 'info@hellothinkster.com',
        error: JSON.stringify(error),
        cc: ['rupa@hellothinkster.com', 'kendra@hellothinkster.com', 'karthik@hellothinkster.com', 'kiran@hellothinkster.com', 'nikhil@hellothinkster.com', 'shyam@hellothinkster.com'],
      };

      yield createTicket(data, sessionToken);

      try {
        let errorMessage = '';
        if (error && error.details && error.details.originalError && error.details.originalError.fieldErrors && error.details.originalError.fieldErrors.length > 0) {
          if (error.details.originalError.fieldErrors[0].fieldErrors && error.details.originalError.fieldErrors[0].fieldErrors.length > 0) {
            errorMessage = error.details.originalError.fieldErrors[0].fieldErrors[0].message;
          }
        }

        const paymentFailureLog = {
          source: 'Registration',
          url: window.location.href,
          user_id: sessionUserId,
          email_address: parentEmail,
          error: JSON.stringify(error),
          error_message: errorMessage,
        };

        yield axios({
          method: 'post',
          baseURL,
          url: '/v1/paymentfailurelogs',
          headers: { Authorization: `JWT ${sessionToken}` },
          data: paymentFailureLog,
        });
      }
      catch (e) {
      }


      if (window !== undefined) {
        window.scrollTo(0, 0);
      }
      yield put(loadingAction(false));
      yield put(stopSubmit(paymentFormName));
      yield put(PaymentActions.paymentError(error));

      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitFailed');
        }
      } catch (error) {
        // ...
      }
      
      const braintreeClientTokenResponse = yield axios({
        method: "post",
        baseURL,
        url: "/v1/paymentmethods/client_token",
        headers: { Authorization: `JWT ${sessionToken}` },
        data: { userId: sessionUserId },
      });

      const braintreeClientTokenResult = braintreeClientTokenResponse.data;
      const braintreeClientToken = braintreeClientTokenResult.clientToken;
      // Set app braintree client token...
      yield put(setBraintreeClientToken(braintreeClientToken));

    }
  }
}