import axios from 'axios';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';

export default function validateReferralCodeAsync(code) {
  return axios({
    method: 'get',
    baseURL: apiBaseURL,
    url: `/v1/codes/${code}`,
  })
  .then(response => response.data);
}
