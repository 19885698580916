/* eslint-disable no-constant-condition */
import axios from 'axios';
import {
  select,
  take,
  put,
} from 'redux-saga/effects';
import { SET_ENROLLCOUPON_CODE, saveEnrollCouponCode, enrollCouponCodeFetchFailed } from '../actions/enrollCouponCode';
import loadingAction from '../actions/loading';
import selectSession from '../selectors/session';
import { setServiceLevels } from '../actions/serviceLevels';

const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';

export default function* applyEnrollCouponCode() {
  while (true) {
    const fetchActions = yield take(SET_ENROLLCOUPON_CODE);
    try {
      const session = yield select(selectSession);
      const couponInfo = fetchActions.payload;
      const parent_id = session.userId;
      yield put(loadingAction(true));

      const response = yield axios({
        method: 'post',
        baseURL: cbBaseURL,
        headers: { Authorization: `JWT ${session.token}`, 'x-api-key': 'ADMIN-PRNT-INSIGHTS-659ED6F2992C8FD4' },
        url: `/save_coupon_summary`,
        data: {
          "parent_id": parent_id,
          "coupon_code": couponInfo.id
        }
      });
      yield put(loadingAction(false));
      const allStudentsSummary = response.data;
      yield put(setServiceLevels(allStudentsSummary));
      yield put(saveEnrollCouponCode(couponInfo.id));
    } catch (error) {
      // console.log(error, 'errorr')        
      yield put(loadingAction(false));
      yield put(enrollCouponCodeFetchFailed(error));
    }
  }
}
