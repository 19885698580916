/* eslint-disable no-constant-condition */
import {
  put,
  select,
  take,
} from 'redux-saga/effects';
import axios from 'axios';
import loadingAction from '../actions/loading';
import errorAction from '../actions/error';
import { SUBMIT_STORE_STUDENT_INFORMATION_FORM } from '../actions/submitStoreStudentInformationForm';
import selectStudents from '../selectors/students';
import routerHistory from '../constants/routerHistory';
import selectSession from '../selectors/session';
import selectGeoLocation from '../selectors/geoLocation';
import { setStudentInfo } from '../actions/studentInfo';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';

export default function* submitStudentInformationFormSaga() {

  while (true) {
    yield take(SUBMIT_STORE_STUDENT_INFORMATION_FORM);
    try {
        const students = yield select(selectStudents);
        const session = yield select(selectSession);

        const sessionToken = session.token;
        const sessionUserId = session.userId;

        const geoLocation = yield select(selectGeoLocation);
        yield put(loadingAction(true));
        // Common for All Students
        let service_id = 'Starter';
        // Create Students
        const savedStudents =
          yield axios.all(createStudents(sessionUserId, sessionToken, students, service_id, geoLocation));

        // Get Only Data
        let studentInfo = [];
        studentInfo = savedStudents.map((student) => student.data);

        window.dataLayer.push({
          event: 'students_account_created',
          students: studentInfo,
        });

        window.dataLayer.push({ parent_id: sessionUserId });

        // Save
        yield put(setStudentInfo(studentInfo));
        yield put(loadingAction(false));

        routerHistory.push({
          pathname: '/step-3-store',
          search: window.location.search,
        });

    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error submitting student information form',
      }));
    }
  }
}

function createStudents(sessionUserId, sessionToken, students, service_id, geoLocation) {
  const requests = [];
  for (let i = 0; i < students.length; i++) {
    const student = students[i];
    const first_name = student.first_name;
    const last_name = student.last_name;

    requests.push(axios({
      method: 'post',
      baseURL,
      url: `/v1/parents/${sessionUserId}/students`,
      headers: { Authorization: `JWT ${sessionToken}` },
      data: {
        first_name: first_name,
        last_name: last_name,
        gender: student.gender || '',
        service_id: service_id,
        grade: student.grade,
      },
    }));
  }

  return requests;
}
