// NOTE: Global `require` is used because style imports are conditional.
import isClient from '../../constants/isClient';


// (Only import styles on client...)
if (isClient) {
  // NOTE: Import styles here.
  // NOTE: Be aware that order of imports determines precedence.
  require('./tm-common.scss');
}
