
import { createAction } from 'redux-actions';

export const FETCH_ENROLLCOUPON_CODE = 'FETCH_ENROLLCOUPON_CODE';
export const SET_ENROLLCOUPON_CODE = 'SET_ENROLLCOUPON_CODE';
export const SAVE_ENROLLCOUPON_CODE = 'SAVE_ENROLLCOUPON_CODE';
export const ENROLLCOUPON_CODE_FAILED = 'ENROLLCOUPON_CODE_FAILED';

export const fetchEntollCouponCode = createAction(FETCH_ENROLLCOUPON_CODE);
export const setEnrollCouponCode = createAction(SET_ENROLLCOUPON_CODE);
export const saveEnrollCouponCode = createAction(SAVE_ENROLLCOUPON_CODE);
export const enrollCouponCodeFetchFailed = createAction(ENROLLCOUPON_CODE_FAILED);
