import React, { Component } from "react";
import { connect } from "react-redux";
import routerHistory from "../../../constants/routerHistory";
import ThinksterLogomark from "../Glyphs/ThinksterLogomark";
import ThinksterWordmark from "../Glyphs/ThinksterWordmark";
import { selectCTA } from "../../../actions/cta";

class Header extends Component {
  state = {
    test: false,
    showStartFreeTrial: true,
    showGetStartedTutoring: false,
    showDiamondPlanGetPrice: false,
    hashLink: true,
  };

  componentDidMount() {
    this.initializeButtonsLogic(this.props);
  }

  componentDidUpdate(preProps) {
    if (preProps.location.pathname !== this.props.location.pathname) {
      this.initializeButtonsLogic(this.props);
    }
  }

  initializeButtonsLogic = (props) => {
    let showStartFreeTrial = true;
    let showGetStartedTutoring = false;
    let showDiamondPlanGetPrice = false;
    let hashLink = true;
    if (typeof props.location !== "undefined") {
      const pathname = props.location.pathname;
      if (
        (pathname &&
          (pathname.indexOf("/step-1") !== -1 ||
            pathname.indexOf("/step-2") !== -1 ||
            pathname.indexOf("/step-3") !== -1 ||
            pathname.indexOf("/thank-you-web-sa") !== -1 ||
            pathname.indexOf("/thank-you-free-dt") !== -1 ||
            pathname.indexOf("/thank-you-store") !== -1 ||
            pathname.indexOf("/prerequisite-foundation-courses") !== -1
          )
        )
      ) {
        showStartFreeTrial = false;
        showDiamondPlanGetPrice = false;
      }
      if (pathname && pathname.indexOf("/high-school-tutoring") !== -1) {
        showGetStartedTutoring = true;
        showStartFreeTrial = false;
        showDiamondPlanGetPrice = false;
      }
      if (pathname && pathname.indexOf("/pricing-math-programs") !== -1) {
        if (this.props.selectedCTA.tutoringPlan === "diamondPlan") {
          showStartFreeTrial = false;
          showDiamondPlanGetPrice = true;
        }
      }
      if (
        pathname &&
        (pathname.indexOf("/step-1") !== -1 ||
          pathname.indexOf("/step-2") !== -1 ||
          pathname.indexOf("/step-3") !== -1)
      ) {
        hashLink = false;
      }
    }
    this.setState({
      showStartFreeTrial,
      showGetStartedTutoring,
      showDiamondPlanGetPrice,
      hashLink,
    });
  }

  handleTermClick = (e) => {
    e.preventDefault();
    this.setState({ test: !this.state.test });
  };

  closeNotification = (e) => {
    document
      .getElementsByClassName("o-notificationBar")[0]
      .parentNode.removeChild(
        document.getElementsByClassName("o-notificationBar")[0]
      );
  };

  render() {
    const { location } = this.props;

    let headerClass = "o-siteHeader";
    if (this.props.stickyHeader) {
      headerClass =
        "o-siteHeader o-siteHeader--hasNotification o-siteHeader--docked";
    }
    if (typeof location !== "undefined") {
      if (location.pathname === "/annual-pricing") return null;
    }
    if (typeof location !== "undefined" && location.pathname === "/step-2-pricing" ) {
      headerClass =
      "o-siteHeader o-siteHeader--hasNotification o-siteHeader--docked";
    }
    
    const { showStartFreeTrial, showGetStartedTutoring, showDiamondPlanGetPrice, hashLink } = this.state;
    return (
      <div className={headerClass}>
        <div className="o-siteHeader__branding">
          <a
            href={hashLink ? "https://www.hellothinkster.com/" : "#"}
            className="o-thinkster asad"
            title="Thinkster"
          >
            <img
              className="thinkster-sat-logo-new"
              src="https://hellothinkster.com/images/thinkstermath-wordmark.svg"
              alt="Thinkster"
            />
            <ThinksterLogomark />
            <ThinksterWordmark />
          </a>
        </div>

        <nav className="o-siteHeader__nav">
          <ul className="o-siteHeader__navItems">
            {showStartFreeTrial && (
              <li className="o-siteHeader__navItem bttnStartFreeTrialMobile">
                <span
                  onClick={() => {
                    this.props.onSelectCTA("top_start_free_trial");
                    routerHistory.push({
                      pathname: "/step-1",
                      search: window.location.search,
                    });
                  }}
                  title="Start Free Trial"
                  className="o-tm-mega-menu__primary__navBtn b-button b-button--active3 registration_cta"
                  style={{cursor: 'pointer'}}
                >
                  Start Free Trial
                </span>
              </li>
            )}
            {showGetStartedTutoring && (
              <li className="o-siteHeader__navItem bttnStartFreeTrialMobile">
                <a
                  href="#sign-in-or-sign-up"
                  title="Get Started / 1:1 Tutoring"
                  className="o-tm-mega-menu__primary__navBtn b-button b-button--active3"
                >
                  Get Started / 1:1 Tutoring
                </a>
              </li>
            )}
            {showDiamondPlanGetPrice && (
              <li className="o-siteHeader__navItem bttnStartFreeTrialMobile">
                <a
                  href="https://hellothinkster.com/apply-diamond.html"
                  target="_blank"
                  rel="noreferrer"
                  title="SCHEDULE  CALL TO GET PRICE"
                  className="c-button c-button--active2"
                >
                  Schedule Call To Get Price
                </a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedCTA: state.selectedCTA,
});

const actionCreators = {
  onSelectCTA: selectCTA,
};

export default connect(mapStateToProps, actionCreators)(Header);
