import { SET_PARENT_INFO } from '../actions/parentInfo';


export default function parentInfo(
  state = [],
  action
) {
  switch (action.type) {
    case SET_PARENT_INFO:
      return action.payload;

    default:
      return state;
  }
}
