import { SET_PLANS } from '../actions/setPlans';

export default function plans(
  state = {},
  action
) {
  switch (action.type) {
    case SET_PLANS:
      return action.payload;
    default:
      return state;
  }
}
