/* eslint-disable no-constant-condition */
import axios from 'axios';
import {
  select,
  take,
  put,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { PAYPAL } from '../actions/paypal';
import loadingAction from '../actions/loading';
import selectSession from '../selectors/session';
import selectSelectedService from '../selectors/selectedService';
import selectParent from '../selectors/parentInfo';
import selectNonce from '../selectors/nonce';
import routerHistory from '../constants/routerHistory';
import studentInfo from '../selectors/studentInfo';
import * as PaymentActions from '../actions/payment';
import selectSelectedPlans from '../selectors/selectedPlans';
import selectSelectedBillingCycle from '../selectors/selectedBillingCycle';
import selectPlans from '../selectors/plans';
import selectStudents from '../selectors/students';
import selectTotals from '../selectors/totals';
import selectCart from '../selectors/cart';
import selectEnrollCouponCode from '../selectors/enrollCouponCode';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';
const paypalFormName = 'PaypalInformationForm';

function pushBillingCycleToDataLayer(cart) {
  window.dataLayer.push({
    event: 'chooseBillingCycle',
    billingCycle: `${cart.billingCycle} month`, // selected billing cycle
  });
}

function pushPurchaseToDataLayer(cart, selectedPlans, plans, sessionUserId, selectedBillingCycle) {
  const products = [];
  selectedPlans.map((selectedPlan) => {
    const { selectedGradeLevel, selectedService } = selectedPlan;
    products.push({
      name: plans[selectedGradeLevel][selectedService][selectedBillingCycle].product_name,
      price: parseFloat(plans[selectedGradeLevel][selectedService][selectedBillingCycle].amount).toFixed(2).toString(),
      category: plans[selectedGradeLevel][selectedService][selectedBillingCycle].cf_service_id,
      quantity: 1,
    });
    return null;
  });
  products.push({
    name: 'Pay Today discount',
    price: `-${cart.payNowDiscount}`,      // It is a negative value
    category: 'Discounts',
    quantity: 1,            // It is always 1
  });
  window.dataLayer.push({
    event: 'purchase',
    billingCycle: `${cart.billingCycle} month`,      // Billing Cycle
    ecommerce: {
      purchase: {
        actionField: {
          id: sessionUserId,           // Transaction ID is required.
          affiliation: 'Hello Thinkster',
          revenue: cart.totalAfterDiscount,     // Final transaction value after discount
          tax: '0.00',             // Leave 0.00
          shipping: '0.00',       // Leave 0.00
          coupon: '',    // Leave empty if not promo code
        },
        products,
      },
    },
  });
}

export default function* submitPaypalSaga() {
  while (true) {
    yield take(PAYPAL);
    try {
      const cart = yield select(selectCart);
      pushBillingCycleToDataLayer(cart);

      const selectedPlans = yield select(selectSelectedPlans);
      const plans = yield select(selectPlans);
      const selectedBillingCycle = yield select(selectSelectedBillingCycle);
      const students = yield select(selectStudents);
      const totals = yield select(selectTotals);

      const selectedService = yield select(selectSelectedService);
      const session = yield select(selectSession);
      const sessionUserId = session.userId;
      const nonce = yield select(selectNonce);

      const service = selectedService && selectedService[0].selectedService ? selectedService[0].selectedService : 'Gold';
      const couponCode = yield select(selectEnrollCouponCode);

      yield put(loadingAction(true));
      yield put(PaymentActions.paypalRequesting());
      yield put(startSubmit(paypalFormName));

      const paymentMethodsResponse = yield axios({
        method: 'post',
        baseURL,
        url: '/v1/paymentmethods',
        data: { userId: session.userId, nonceFromTheClient: nonce, app: "enroll", payment_method: "paypal" },
        headers: { Authorization: `JWT ${session.token}` },
      });

      const savedStudents = yield select(studentInfo);
      const studentIds = [];
      savedStudents.map((student) => studentIds.push(student.id));

      // Get Current URL
      if (paymentMethodsResponse && paymentMethodsResponse.data.paymentMethod.token) {
        yield axios({
          method: 'post',
          baseURL: cbBaseURL,
          url: '/create_payment_method',
          data: {
            customer_id: sessionUserId,
            token: paymentMethodsResponse.data.paymentMethod.token,
            type: 'paypal',
            coupon_code: couponCode ? couponCode.code : ''
          },
        });

      }

      yield put(stopSubmit(paypalFormName));
      yield put(loadingAction(false));

      try {
        //Pushing total checkout amount - this is passed to facebook as customer value
        if (totals) {
          var totalCheckoutAmount = 0;
          if (selectedBillingCycle.toString() === '12' && totals.annualTotal) {
            totalCheckoutAmount = totals.annualTotal.totalBeforeDiscount;
          } else if (selectedBillingCycle.toString() === '6' && totals.semiAnnualTotal) {
            totalCheckoutAmount = totals.semiAnnualTotal.totalBeforeDiscount;
          } else if (selectedBillingCycle.toString() === '3' && totals.quaterlyTotal) {
            totalCheckoutAmount = totals.quaterlyTotal.totalBeforeDiscount;
          } else if (totals.monthlyTotal) {
            totalCheckoutAmount = totals.monthlyTotal.totalBeforeDiscount;
          }
          window.dataLayer.push({ total_checkout_amount: totalCheckoutAmount });
        }

        window.dataLayer.push({
          event: 'payment_completed',
          sessionUserId,
          students,
          selectedPlans,
          selectedBillingCycle,
        });
        pushPurchaseToDataLayer(cart, selectedPlans, plans, sessionUserId, selectedBillingCycle);
      } catch(err) {
        // Skip
      }
      
      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitSuccessful');
        }
      } catch (error) {
        // ...
      }

      // Check if Registration Flow is present
      let path = '/thank-you-web-sa';
      if (service === 'Lite') {
        path = '/thank-you-lite';
      }  else if (service === 'GuidedLearning') {
        path = '/guided-learning-purchase';
      }

      routerHistory.push({
        pathname: path,
        search: window.location.search,
      });
    } catch (error) {
      const session = yield select(selectSession);
      const sessionToken = session.token;
      const sessionUserId = session.userId;
      const parent = yield select(selectParent);
      let parentEmail;
      if (parent && parent.email_address) {
        parentEmail = parent.email_address;
      }
      const data = {
        subject: 'Registration flow - Payment failed Notification!!!',
        body: `User ID: ${sessionUserId}\n` +
              `User Email-ID: ${parentEmail}\n` +
              `error: ${JSON.stringify(error)}\n`,
        user: sessionUserId,
        email: 'info@hellothinkster.com',
        error: JSON.stringify(error),
        cc: ['rupa@hellothinkster.com', 'kendra@hellothinkster.com', 'karthik@hellothinkster.com', 'kiran@hellothinkster.com', 'nikhil@hellothinkster.com'],
      };
      yield axios({
        method: 'post',
        baseURL,
        url: 'v1/zendesk/ticket',
        headers: { Authorization: `JWT ${sessionToken}` },
        data,
      });

      if (window !== undefined) {
        window.scrollTo(0, 0);
      }
      yield put(loadingAction(false));
      yield put(stopSubmit(paypalFormName));
      yield put(PaymentActions.paypalError(error));

      // Try Hotjar
      try {
        if (window && window.hj) {
          window.hj('formSubmitFailed');
        }
      } catch (error) {
        // ...
      }

    }
  }
}
