import { select, take, put } from 'redux-saga/effects';

import axios from 'axios';
import errorAction from '../actions/error';
import loadingAction from '../actions/loading';

import selectSession from '../selectors/session';
import { UPDATE_PLAN_DETAILS } from '../actions/updatePlanDetails';
import routerHistory from '../constants/routerHistory';
import selectSelectedBillingCycle from '../selectors/selectedBillingCycle';
import { setServiceLevels } from '../actions/serviceLevels';
import selectSelectedService from '../selectors/selectedService';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';

export default function* updatePlanDetails() {
  while (true) {
    yield take(UPDATE_PLAN_DETAILS);
    const selectedBillingCycle = yield select(selectSelectedBillingCycle);
    const selectedService = yield select(selectSelectedService);
    let service = selectedService[0].selectedService;
    const session = yield select(selectSession);
    try {
      yield put(loadingAction(true));
      yield axios({
        method: 'put',
        baseURL,
        headers: { Authorization: `JWT ${session.token}` },
        url: `/v1/parents/${session.userId}/students/enrollBilling`,
        data: {
          service_id: service,
          billing_frequency: selectedBillingCycle
        },
      });
      const url = `/get_order_summary?parent_id=${session.userId}`;
      const serviceLevels = yield  axios({
          method: 'get',
          baseURL: cbBaseURL,
          url
      });
      yield put(loadingAction(false));
      if(serviceLevels && serviceLevels.data){
        yield put(setServiceLevels(serviceLevels.data));
      }
      routerHistory.push({
        pathname: '/step-3',
        search: window.location.search,
      });
    } catch (error) {
      yield put(loadingAction(false));
      yield put(errorAction({
        error,
        title: 'Error submitting billing information',
      }));
    }
  }
}