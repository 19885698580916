import { SELECT_CTA, SELECT_TUTORING_PLAN } from '../actions/cta';

export default function selectedCTA(state = {startFreeTrialCTA: 'top_start_free_trial', tutoringPlan: 'Regular'}, action) {
  switch (action.type) {
    case SELECT_CTA:
      return { ...state, startFreeTrialCTA: action.payload };
    case SELECT_TUTORING_PLAN:
        return { ...state, tutoringPlan: action.payload };
    default:
      return state;
  }
}
