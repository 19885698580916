import React from 'react';
import Routes from './routes';

require('es6-promise').polyfill();

const Router = () => (
  <Routes />
);

export default Router;
