import { put, select, takeEvery } from 'redux-saga/effects';
import { SELECT_PLAN } from '../actions/selectPlans';
import selectPlans from '../selectors/plans';
import errorAction from '../actions/error';

function pushToDataLayer(plans, selectedPlan) {
  const { selectedGradeLevel, selectedService } = selectedPlan;
  try {
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [{
            name: plans[selectedGradeLevel][selectedService][1].product_name,
            price: parseFloat(plans[selectedGradeLevel][selectedService][1].amount).toFixed(2).toString(),
            category: plans[selectedGradeLevel][selectedService][1].product_short_name,
            quantity: 1,
          }],
        },
      },
    });
  } catch (error) {
    // Ignore
  }

}

function* selectNewPlan(action) {
  try {
    const plans = yield select(selectPlans);
    pushToDataLayer(plans, action.payload);
    yield put({ type: 'PLAN_SELECTED', payload: action.payload });
    yield put({ type: 'UPDATE_TOTALS' });
  } catch (error) {
    yield put(errorAction({
      error,
      title: 'Error fetching IP address geo location',
    }));
  }
}

export default function* selectPlan() {
  yield takeEvery(SELECT_PLAN, selectNewPlan);
}

