/* eslint-disable no-constant-condition */
import { select, take, put } from 'redux-saga/effects';
import axios from 'axios';
import errorAction from '../actions/error';
import loadingAction from '../actions/loading';
import selectSession from '../selectors/session';
import { SET_PRICE } from '../actions/setPricing';
import { setServiceLevels } from '../actions/serviceLevels';
const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';

export default function* setPricingFromSaga() {
  while (true) {
    // yield take(SET_PRICE);
    const fetchActions = yield take(SET_PRICE);
    const studentInfo = fetchActions.payload;
    const session = yield select(selectSession);
    const parent_id = session.userId;
    try {
      yield put(loadingAction(true));
      const response = yield axios({
        method: 'post',
        baseURL: cbBaseURL,
        headers: { Authorization: `JWT ${session.token}`, 'x-api-key': 'ADMIN-PRNT-INSIGHTS-659ED6F2992C8FD4' },
        url: `/save_student_signup_plan`,
        data: {
          "parent_id": parent_id,
          "student_id": studentInfo.student_id,
          "service_id": studentInfo.service_id,
          "billing_frequency": studentInfo.billing_frequency,
          "coupon_code": studentInfo.coupon_code
        }
      });
      yield put(loadingAction(false));
      const allStudentsSummary = response.data;
      yield put(setServiceLevels(allStudentsSummary));
    } catch (error) {
      yield put(loadingAction(false));
      yield put(errorAction({
        error,
        title: 'Error submitting student information form',
      }));
    }
  }
}
