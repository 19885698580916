/* eslint-disable no-constant-condition */
import { put, take } from 'redux-saga/effects';
import axios from 'axios';
import { GET_PRODUCTS } from '../actions/product';
import { setProducts } from '../actions/setProducts';
import errorAction from '../actions/error';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';

export default function* fetchProductsSaga() {
  while (true) {
    yield take(GET_PRODUCTS);
    try {
      const productsResponse = yield axios({
        method: 'get',
        baseURL,
        url: `/v1/products`,
      });
      const products = productsResponse.data;

      yield put(setProducts(products));
    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error fetching products',
      }));
    }
  }
}
