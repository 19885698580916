import { LOADING } from '../actions/loading';


export default function loading(
  state = false,
  action
) {
  switch (action.type) {
    case LOADING:
      return action.payload;

    default:
      return state;
  }
}
