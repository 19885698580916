/* eslint-disable no-constant-condition */
import axios from 'axios';
import {
  select,
  take,
  put,
} from 'redux-saga/effects';
import { FETCH_COUPON_CODE, setCouponCode } from '../actions/couponCode';
import loadingAction from '../actions/loading';
import selectSession from '../selectors/session';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';


export default function* fetchCouponCode() {
  while (true) {
    yield take(FETCH_COUPON_CODE);
    try {
      const session = yield select(selectSession);
      const sessionUserId = session.userId;
      yield put(loadingAction(true));

      const response = yield axios({
        method: 'get',
        baseURL,
        url: `/v1/${sessionUserId}/coupon`,
        headers: { Authorization: `JWT ${session.token}` },
      });
      yield put(setCouponCode(response.data));
    } catch (error) {
      

    }
  }
}
