import { put, take, select } from 'redux-saga/effects';
import { REMOVE_PLAN } from '../actions/selectPlans';
import selectPlans from '../selectors/plans';
import selectSelectedPlans from '../selectors/selectedPlans';
import errorAction from '../actions/error';
import _ from 'lodash';

function pushToDataLayer(plans, selectedPlans, removedPlanId) {
  const { selectedGradeLevel, selectedService } = _.find(selectedPlans, { id: removedPlanId });
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [{
          name: plans[selectedGradeLevel][selectedService][1].product_name,
          price: plans[selectedGradeLevel][selectedService][1].amount,
          category: plans[selectedGradeLevel][selectedService][1].service_id,
          quantity: 1,
        }],
      },
    },
  });
}

export default function* removePlan() {
  while (true) {
    try {
      const action = yield take(REMOVE_PLAN);
      const plans = yield select(selectPlans);
      const selectedPlans = yield select(selectSelectedPlans);
      pushToDataLayer(plans, selectedPlans, action.payload);
      yield put({ type: 'PLAN_REMOVED', payload: action.payload });
      yield put({ type: 'UPDATE_TOTALS' });
    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error fetching IP address geo location',
      }));
    }
  }
}
