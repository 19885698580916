import React, { Component } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default class PhoneNumberInput extends Component {

  getFormLabelClass = () => {
    const { input, meta } = this.props;
    let className = 'b-formTextInput b-formTextInput--mobile';
    if (input.value) {
      className += ' b-formTextInput--hasValue';
    }
    if (meta && meta.touched && !meta.error && input.value) {
      className += ' b-formTextInput--confirm';
    }
    if (meta && meta.touched && meta.error) {
      className += ' b-formTextInput--error';
    }
    return className;
  }

  handleOnBlur = (e) => {
    e.preventDefault();
    this.props.input.onBlur();
  }

  render() {
    const {
      type,
      input,
      meta,
      placeholder,
      maxLength,
      countryCode,
    } = this.props;
    const { value, onChange } = input;
    let phoneValue = value;
    if (value && value.indexOf('+') < 0) {
      phoneValue = '+' + phoneValue; 
    }
    phoneValue = phoneValue.replace(' ', '')
    return (
      <label className={this.getFormLabelClass()}>
        <PhoneInput
          value={phoneValue}
          onChange={onChange}
          international
          defaultCountry={countryCode}
          maxLength={maxLength}
          type={type}
          className="b-formTextInput__input"
          onBlur={this.handleOnBlur}
          autoComplete="new-password"
        />
        <span className="b-formTextInput__label desktopDisplayBlockLabel">
          {placeholder} {meta.touched && meta.error ? ` - ${meta.error}` : ''}
        </span>
        <span className="b-formTextInput__label mobileDisplayBlockLabel">
          {meta.touched && meta.error ? `${meta.error}` : `${placeholder}`}
        </span>
      </label>
    ) 
  }
    
}