import validateEmail from '../../../../utils/validateEmail';

export default function validateParentInformationForm(values) {
  const isPhoneValid = values.phoneNumber && values.phoneNumber.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im); 
  return {
    firstName: !values.firstName && 'Parent\'s First Name is required',
    lastName: !values.lastName && 'Parent\'s Last Name is required',
    email: !validateEmail(values.email) && 'Email is invalid',
    postalCode: !values.postalCode && 'Postal code is required',
    phoneNumber: (!values.phoneNumber || (values.phoneNumber && values.phoneNumber.trim() === '') || !isPhoneValid ) && 'Please enter a valid mobile number',
    password: (!values.password || values.password.length < 6) && 'Password must be at least 6 characters',
  };
}
