import { put, take, select } from 'redux-saga/effects';
import { UPDATE_TOTALS } from '../actions/selectPlans';
import updateCart from '../actions/updateCart';
import selectPlans from '../selectors/plans';
import selectGeoLocation from '../selectors/geoLocation';
import selectSelectedPlans from '../selectors/selectedPlans';
import selectSelectedService from '../selectors/selectedService';
import selectSelectedStudents from '../selectors/students';
import errorAction from '../actions/error';
import * as gradeUtil from '../utils/grade';
import selectSelectedBillingCycle from '../selectors/selectedBillingCycle';


export default function* updateTotals() {
  while (true) {
    try {
      yield take(UPDATE_TOTALS);
      const plans = yield select(selectPlans);
      const selectedPlans = yield select(selectSelectedPlans);
      const selectedService = yield select(selectSelectedService);
      const selectedStudents = yield select(selectSelectedStudents);
      const geoLocation = yield select(selectGeoLocation);

      // Common for All Students
      let service = selectedService[0].selectedService;

      let monthlyTotalBeforeDiscount = 0;
      let quaterlyTotalBeforeDiscount = 0;
      let semiAnnualTotalBeforeDiscount = 0;
      let annualTotalBeforeDiscount = 0;
      let grade_range = gradeUtil.GRADE_K_5;
      selectedStudents.map((selectedStudent) => {
        grade_range = gradeUtil.range(selectedStudent.grade);

        // Fix for India
        if(geoLocation && geoLocation.country_code && geoLocation.country_code === "IN"){
          grade_range = '1 - 6';
        }

        const planDetails = plans[grade_range][service];
        monthlyTotalBeforeDiscount += planDetails[1] ? parseInt(planDetails[1].price, 10) : 0;
        quaterlyTotalBeforeDiscount += planDetails[3] ? parseInt(planDetails[3].price, 10) : 0;
        semiAnnualTotalBeforeDiscount += planDetails[6] ? parseInt(planDetails[6].price, 10) : 0;
        annualTotalBeforeDiscount += planDetails[12] ? parseInt(planDetails[12].price, 10) : 0;
        return null;
      });

      if(geoLocation && geoLocation.country_code && geoLocation.country_code === "IN") {
        grade_range = '1 - 6';
      }
      const selectedBillingCycle = yield select(selectSelectedBillingCycle);
      // Get Country
      let country = plans[grade_range][service][selectedBillingCycle].country;

      // For US,CN,AU
      let siblingDiscountPerPlan = 10;
      if(country && country === "IN"){
        siblingDiscountPerPlan = 100;
      }
      if(country && country === "GB"){
        siblingDiscountPerPlan = 5;
      }

      let siblingDiscount = siblingDiscountPerPlan * (selectedPlans.length -1);

      let payNowDiscount = 0; //parseFloat(monthlyTotalBeforeDiscount * 0.15).toFixed(2);
      let savings = siblingDiscount;
      const monthlyTotal = { billingCycle: 1,
                          totalBeforeDiscount: monthlyTotalBeforeDiscount,
                          payNowDiscount,
                          savings,
                          siblingDiscount: parseFloat(siblingDiscount).toFixed(2),
                          totalDiscount: parseFloat(payNowDiscount + savings).toFixed(2),
                          totalAfterDiscount: parseFloat(monthlyTotalBeforeDiscount - payNowDiscount).toFixed(2),
                        };
      //payNowDiscount = quaterlyTotalBeforeDiscount * 0.15;
      siblingDiscount = 10.00 * (selectedPlans.length -1) * 3;
      savings = ((3 * monthlyTotalBeforeDiscount) - quaterlyTotalBeforeDiscount) + siblingDiscount;
      const quaterlyTotal = { billingCycle: 3,
                          totalBeforeDiscount: quaterlyTotalBeforeDiscount,
                          payNowDiscount,
                          savings,
                          siblingDiscount: parseFloat(siblingDiscount).toFixed(2),
                          totalDiscount: parseFloat(payNowDiscount + savings).toFixed(2),
                          totalAfterDiscount: parseFloat(quaterlyTotalBeforeDiscount - payNowDiscount).toFixed(2),
                        };
      savings = ((6 * monthlyTotalBeforeDiscount) - semiAnnualTotalBeforeDiscount) + siblingDiscount;
      const semiAnnualTotal = { billingCycle: 6,
                          totalBeforeDiscount: semiAnnualTotalBeforeDiscount,
                          payNowDiscount,
                          savings,
                          siblingDiscount: parseFloat(siblingDiscount).toFixed(2),
                          totalDiscount: parseFloat(payNowDiscount + savings).toFixed(2),
                          totalAfterDiscount: parseFloat(semiAnnualTotalBeforeDiscount - payNowDiscount).toFixed(2),
                        };

      savings = ((12 * monthlyTotalBeforeDiscount) - annualTotalBeforeDiscount) + siblingDiscount;
      const annualTotal = { billingCycle: 12,
                          totalBeforeDiscount: annualTotalBeforeDiscount,
                          payNowDiscount,
                          savings,
                          siblingDiscount: parseFloat(siblingDiscount).toFixed(2),
                          totalDiscount: parseFloat(payNowDiscount + savings).toFixed(2),
                          totalAfterDiscount: parseFloat(annualTotalBeforeDiscount - payNowDiscount).toFixed(2),
                        };

      yield put({ type: 'TOTALS_UPDATED', payload: { monthlyTotal, quaterlyTotal, semiAnnualTotal, annualTotal } });
      yield put(updateCart(monthlyTotal));
    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error fetching IP address geo location',
      }));
    }
  }
}
