import { SET_STUDENT_INFO } from '../actions/studentInfo';

export default function studentInfo(
  state = [],
  action
) {
  switch (action.type) {
    case SET_STUDENT_INFO:
      return action.payload;
    default:
      return state;
  }
}