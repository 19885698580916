import { SET_COUPON_CODE } from '../actions/couponCode';

export default function couponCode(
  state = 'thinkster',
  action
) {
  switch (action.type) {
    case SET_COUPON_CODE:
      return action.payload;
    default:
      return state;
  }
}
