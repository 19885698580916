/* eslint-disable no-constant-condition */
import axios from 'axios';
import {
  select,
  take,
  put,
} from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { create as callbackCreateBraintreeClient } from 'braintree-web/client';
import { STORE_CHECKOUT } from '../actions/storeCart';
import loadingAction from '../actions/loading';
import selectBraintreeClientToken from '../selectors/braintreeClientToken';
import selectPaymentInformationFormValues from '../selectors/paymentInformationFormValues';
import selectSession from '../selectors/session';
import selectParent from '../selectors/parentInfo';
import routerHistory from '../constants/routerHistory';
import * as PaymentActions from '../actions/payment';
import selectStoreCart from '../selectors/storeCart';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const paymentFormName = 'PaymentInformationForm';

function createBraintreeClient(braintreeClientToken) {
  return new Promise((resolve, reject) => {
    callbackCreateBraintreeClient({
      authorization: braintreeClientToken,
    }, (error, client) => {
      if (error) {
        return reject(error);
      }

      return resolve(client);
    });
  });
}

function sendBraintreeRequest(braintreeClient, braintreeRequestBody) {
  return new Promise((resolve, reject) => {
    braintreeClient.request({
      endpoint: 'payment_methods/credit_cards',
      method: 'post',
      data: braintreeRequestBody,
    }, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}

function pushPurchaseToDataLayer(storeCart, sessionUserId) {
  const products = [];
  storeCart.items.map((storeItem) => {
    const { name, price, quantity } = storeItem;
    products.push({
      name,
      price,
      category: 'Math Store',
      quantity,
    });
    return null;
  });
  products.push({
    name: 'discount',
    price: `-${storeCart.discount}`,      // It is a negative value
    category: 'Discounts',
    quantity: 1,            // It is always 1
  });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: sessionUserId,           // Transaction ID is required.
          affiliation: 'Hello Thinkster',
          revenue: storeCart.total,     // Final transaction value after discount
          tax: '0.00',             // Leave 0.00
          shipping: '0.00',       // Leave 0.00
          coupon: '',    // Leave empty if not promo code
        },
        products,
      },
    },
  });
}

export default function* checkout() {
  while (true) {
    yield take(STORE_CHECKOUT);
    try {
      const storeCart = yield select(selectStoreCart);
      const session = yield select(selectSession);
      const sessionToken = session.token;
      const sessionUserId = session.userId;
      let createOrderResponse = null;
      if (storeCart.total > 0) {
        const braintreeClientToken = yield select(selectBraintreeClientToken);
        const paymentInformationFormValues = yield select(selectPaymentInformationFormValues);
        const creditCardValues = paymentInformationFormValues.creditCard || {};
        const billingAddressValues = creditCardValues.billingAddress || {};
        const braintreeRequestBody = {
          creditCard: {
            number: creditCardValues.number,
            cvv: creditCardValues.cvv,
            expirationDate: `${creditCardValues.expirationMonth}/${creditCardValues.expirationYear}`,
            billingAddress: {
              firstName: billingAddressValues.firstName,
              lastName: billingAddressValues.lastName,
              streetAddress: billingAddressValues.streetAddress,
              locality: billingAddressValues.city,
              postalCode: billingAddressValues.postalCode,
              countryCodeAlpha2: creditCardValues.countryCode,
            },
          },
        };
        yield put(loadingAction(true));
        yield put(PaymentActions.paymentRequesting());
        yield put(startSubmit(paymentFormName));
        createOrderResponse = yield axios({
          method: 'post',
          baseURL,
          url: '/v1/orders',
          headers: { Authorization: `JWT ${sessionToken}` },
          data: {
            owner_id: sessionUserId,
            total: storeCart.total,
            discount: storeCart.discount,
            discount_percent: storeCart.discountPercent,
            currency_unit: '$',
            items: storeCart.items,
          },
        });
        const braintreeClient = yield createBraintreeClient(braintreeClientToken);
        const braintreeResponse = yield sendBraintreeRequest(braintreeClient, braintreeRequestBody);
        yield put(PaymentActions.paymentSuccess(braintreeResponse.creditCards));
        const creditCards = braintreeResponse.creditCards || [];
        const creditCard = creditCards[0] || {};

        // Create payment method...
        yield axios({
          method: 'post',
          baseURL,
          url: '/v1/purchases',
          headers: { Authorization: `JWT ${sessionToken}` },
          data: {
            userId: sessionUserId,
            nonceFromTheClient: creditCard.nonce,
            orderId: createOrderResponse.data.id,
          },
        });
      }

      yield put(stopSubmit(paymentFormName));
      yield put(loadingAction(false));

      try {
        pushPurchaseToDataLayer(storeCart, sessionUserId);
      } catch (err) {}

      let purchaseCategory = 'Math';
      let productName = '';
      if (storeCart.items && storeCart.items.length > 0 && storeCart.items[0]) {
        purchaseCategory = storeCart.items[0].category;
        productName = storeCart.items[0].name;
      }
      let path = '/thank-you-store';
      if (purchaseCategory === 'TutoringSession') {
        path = '/thank-you-tutoring-session';
      } else if (purchaseCategory === 'SummerProgram') {
        path = '/thank-you-summer-program';
      } else {
        if (productName === 'Art of Word Problems') {
          path = '/thank-you-art-of-word-problem';
        }
      }
      routerHistory.push({
        pathname: path,
        search: window.location.search,
      });
    } catch (error) {
      const session = yield select(selectSession);
      const sessionToken = session.token;
      const sessionUserId = session.userId;
      const parent = yield select(selectParent);
      let parentEmail;
      if (parent) {
        parentEmail = parent.email_address;
      }
      const data = {
        subject: 'Store checkout flow - Payment failed Notification!!!',
        body: `User ID: ${sessionUserId}\n` +
              `User Email-ID: ${parentEmail}\n` +
              `error: ${JSON.stringify(error)}\n`,
        user: sessionUserId,
        email: 'info@hellothinkster.com',
        error: JSON.stringify(error),
        cc: ['rupa@hellothinkster.com', 'kendra@hellothinkster.com', 'karthik@hellothinkster.com', 'nikhil@hellothinkster.com'],
      };
      yield axios({
        method: 'post',
        baseURL,
        url: 'v1/zendesk/ticket',
        headers: { Authorization: `JWT ${sessionToken}` },
        data,
      });

      if (window !== undefined) {
        window.scrollTo(0, 0);
      }
      yield put(loadingAction(false));
      yield put(stopSubmit(paymentFormName));
      yield put(PaymentActions.paymentError(error));
    }
  }
}
