import { SET_MOST_POPULAR_PLAN } from '../actions/mostPopularPlan';

export default function plans(
  state = {},
  action
) {
  switch (action.type) {
    case SET_MOST_POPULAR_PLAN:
      return action.payload;
    default:
      return state;
  }
}
