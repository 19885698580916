import {
  SAVE_ENROLLCOUPON_CODE,
  ENROLLCOUPON_CODE_FAILED,
} from "../actions/enrollCouponCode";

export default function enrollCouponCode(
  state = { code: "", error: "" },
  action
) {
  switch (action.type) {
    case SAVE_ENROLLCOUPON_CODE:
      return { code: action.payload, error: "" };
    case ENROLLCOUPON_CODE_FAILED:
      return { code: "", error: action.payload };
    default:
      return state;
  }
}
