import { createAction } from 'redux-actions';

export const ADD_STUDENT = 'ADD_STUDENT';
export const REMOVE_STUDENT = 'REMOVE_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const CLEAR_STUDENTS = 'CLEAR_STUDENTS'

export const addStudent = createAction(ADD_STUDENT);
export const removeStudent = createAction(REMOVE_STUDENT);
export const updateStudent = createAction(UPDATE_STUDENT);
export const clearStudents = createAction(CLEAR_STUDENTS);