import { SET_GRADE_RESULT } from '../actions/setGradeOptions';


export default function gradeResult(
  state = null,
  action
) {
  switch (action.type) {
    case SET_GRADE_RESULT:
      return action.payload;

    default:
      return state;
  }
}