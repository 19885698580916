import { createAction } from 'redux-actions';

export const ADD_TO_STORE_CART = 'ADD_TO_STORE_CART';
export const STORE_CART_UPDATED = 'STORE_CART_UPDATED';
export const STORE_CHECKOUT = 'STORE_CHECKOUT';
export const STORE_CART_ADD_DISCOUNT = 'STORE_CART_ADD_DISCOUNT';

export const addToStoreCart = createAction(ADD_TO_STORE_CART);
export const storeCartUpdated = createAction(STORE_CART_UPDATED);
export const storeCartAddDiscount = createAction(STORE_CART_ADD_DISCOUNT);
export const storeCheckout = createAction(STORE_CHECKOUT);
