import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import saga from '../sagas';
import reducer from '../reducers';


const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
  sagaMiddleware,
)(createStore);

export default function getStore(initialState) {
  const store = createStoreWithMiddleware(reducer, initialState);

  // Run saga via sagaMiddleware
  // NOTE: This must be done after the saga middleware is mounted on the store
  // via applyMiddleware.
  sagaMiddleware.run(saga);

  return store;
}
