import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    const copyrightYear = (new Date()).getFullYear();

    return (
      <div className="o-siteFooter o-siteFooter-padNil">
        <div className="a-container">
          <p className="a-p(15) a-p(15)--breaker a-color(copy-1)">
            Copyright &copy; {copyrightYear} Thinkster Learning, Inc. All rights reserved.
          </p>
          <p className="a-p(15) a-p(15)--breaker">
            Any unauthorized redistribution or reproduction of any copyrighted materials on this website is strictly prohibited.
            <br></br>
            Other product and company names are trademarks of their respective owners.
            <br></br>
            This website contains simulated images; actual appearance may vary.
          </p>
          <p className="a-p(15) a-p(15)--breaker">
            <a href="https://www.hellothinkster.com/privacy.html" title="Privacy Policy">
              Privacy Policy
            </a>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <a href="https://www.hellothinkster.com/terms.html" title="Terms of Use">
              Terms of Use
            </a>
          </p>
        </div>
      </div>
    );
  }
}
