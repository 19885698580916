/* eslint-disable no-constant-condition */
import cookie from 'cookie';
import isClient from '../constants/isClient';

export default function setDataLayerExperimentId() {
  if (!isClient) {
    return;
  }

  if (window && window.dataLayer) {
    const cookies = cookie.parse(document.cookie);
    const experimentId = cookies.utm_expid;

    // NOTE: window.dataLayer is an observed array that must be mutated...
    window.dataLayer.push({ experiment_id: experimentId });
  }
}
