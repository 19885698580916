import { SET_PARENT_INFORMATION_FORM_EMAIL_ERROR } from '../actions/setParentInformationFormErrors';

// type ParentInformationFormErrors = {
//   emailAlreadyExists: false,
// };

export default function parentInformationFormErrors(
  state = null,
  action
) {
  switch (action.type) {
    case SET_PARENT_INFORMATION_FORM_EMAIL_ERROR:
      return { ...state, emailAlreadyExists: action.payload };

    default:
      return state;
  }
}
