import { SET_URL_PARAMETERS } from '../actions/urlParameter';

export default function experiments(state = { email: '', fn: '', ln: '', utmSource: '' }, action) {
  switch (action.type) {
    case SET_URL_PARAMETERS:
      return action.payload;
    default:
      return state;
  }
}
