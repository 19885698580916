const cart = (state = { billingCycle: 6, payNowDiscount: 0, totalDiscount: 0, totalAfterDiscount: 0 }, action) => {
  switch (action.type) {
    case 'UPDATE_CART':
      return action.payload;
    default:
      return state;
  }
};

export default cart;
