/* eslint-disable no-constant-condition */
import { put, take, select } from 'redux-saga/effects';
import axios from 'axios';
import { GET_PARENT_INFO, setParentInfo } from '../actions/parentInfo';
import selectSession from '../selectors/session';
import errorAction from '../actions/error';
import loadingAction from '../actions/loading';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';

export default function* setParentInfoSaga() {
  while (true) {
    yield take(GET_PARENT_INFO);
    const session = yield select(selectSession);
    yield put(loadingAction(true));
    try {
      const parentInfoResponse = yield axios({
        method: 'get',
        baseURL,
        headers: { Authorization: `JWT ${session.token}` },
        url: `/v1/parents/${session.userId}`,
      });
      yield put(setParentInfo(parentInfoResponse.data));
      yield put(loadingAction(false));
    } catch (error) {
      yield put(loadingAction(false));
      yield put(errorAction({
        error,
        title: 'Error fetching grade options',
      }));
    }
  }
}
