import { SET_REFERRAL_CODE_VALUE_METADATA } from '../actions/setReferralCodeInputValueMetadata';

const ReferralCodeMetadata = {
  braintree_discount_id: '',
  braintree_discount_in_percentage: false,
  code: '',
  description: '',
  discountMessage: '',
  name: '',
  top_up_discount: false,
  type: '',
  numberOfTrialDaysDuringRegistration: '',
  numberOfTrialDaysAfterNewPayment: ''
};

export default function referralCodeInputValueMetadata(
  state = ReferralCodeMetadata,
  action
) {
  switch (action.type) {
    case SET_REFERRAL_CODE_VALUE_METADATA:
      return action.payload;

    default:
      return state;
  }
}
