import React, { Component } from 'react';
export default class RegistrationFormTextInput extends Component {
  static defaultProps = {
    component: 'input',
  };

  // props: Props;

  getFormLabelClass() {
    const { input, meta } = this.props;
    let className = 'b-formTextInput';
    if (input.value) {
      className += ' b-formTextInput--hasValue';
    }
    if (meta && meta.touched && !meta.error && input.value) {
      className += ' b-formTextInput--confirm';
    }
    if (meta && meta.touched && meta.error) {
      className += ' b-formTextInput--error';
    }
    return className;
  }

  render() {
    const {
      type,
      input,
      meta,
      placeholder,
      maxLength,
      autocomplete
    } = this.props;
    return (
      <label className={this.getFormLabelClass()}>
        <input
          {...input}
          
          maxLength={maxLength}
          type={type}
          className="b-formTextInput__input"
          //https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
          autocomplete={autocomplete}
        />
        <span className="b-formTextInput__label desktopDisplayBlockLabel">
          {placeholder} {meta.touched && meta.error ? ` - ${meta.error}` : ''}
        </span>
        <span className="b-formTextInput__label mobileDisplayBlockLabel">
          {meta.touched && meta.error ? `${meta.error}` : `${placeholder}`}
        </span>
      </label>
    );
  }
}
