import { SET_PARENT_INFORMATION_FORM_INTIAL_VALUES, CLEAR_PARENT } from '../actions/setParentInformationFormInitialValues';

// type ParentInformationFormInitialValues = {
//   [key: string]: ?string,
// };

export default function parentInformationFormInitialValues(
  state = null,
  action
) {
  switch (action.type) {
    case SET_PARENT_INFORMATION_FORM_INTIAL_VALUES:
      return action.payload;

    case CLEAR_PARENT :
      return null;
    default:
      return state;
  }
}
