import axios from 'axios';

const apiBaseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const emailValidationURL = 'v1/users/validate';

export default function validateEmailAsync(email) {
  return axios({
    method: 'get',
    baseURL: apiBaseURL,
    url: emailValidationURL,
    params: {
      login: email,
    },
  });
}
