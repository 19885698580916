import { SET_GEO_LOCATION } from '../actions/setGeoLocation';

const initialState = {
  ip: '',
  country_code: '',
  country_name: '',
  region_code: '',
  region_name: '',
  city: '',
  zip_code: '',
  time_zone: '',
  latitude: null,
  longitude: null,
  metro_code: null,
};

export default function geoLocation(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_GEO_LOCATION:
      return action.payload;

    default:
      return state;
  }
}
