import React, { Component } from "react";
import ThinksterLogomark from "../Glyphs/ThinksterLogomark";
import ThinksterWordmark from "../Glyphs/ThinksterWordmark";
import GlyphClose from "../Glyphs/GlyphClose";

export default class Header extends Component {
  render() {
    let showTopMenu = false;
    return (
      <div
        className={`o-mobileNav js-mobileNav ${
          this.props.showNav ? "-view-show" : ""
        }`}
      >
        <div className="o-mobileNav__branding">
          <a
            href="https://www.hellothinkster.com/"
            className="o-thinkster o-thinkster--stacked"
            title="Thinkster"
          >
            <ThinksterLogomark />
            <ThinksterWordmark />
          </a>
        </div>
        <nav className="o-mobileNav__nav">
          <ul className="o-mobileNav__navItems">
            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/about-us.html"
                  title="About Us"
                  className="o-mobileNav__navLink"
                >
                  About Us
                </a>
              </li>
            ) : (
              ""
            )}
            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/get/math-tutor/"
                  title="Why It Works"
                  className="o-mobileNav__navLink"
                >
                  Why It Works
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/get/how-our-math-tutor-app-works/"
                  title="How It Works"
                  className="o-mobileNav__navLink"
                >
                  How It Works
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/parent-insight.html"
                  title="Parent App"
                  className="o-mobileNav__navLink"
                >
                  Parent App
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/online-math-tutor.html"
                  title="Our Tutors"
                  className="o-mobileNav__navLink"
                >
                  Our Tutors
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://demo.hellothinkster.com/"
                  title="Take a Tour"
                  className="o-mobileNav__navLink"
                >
                  Take a Tour
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="/pricing-math-programs"
                  title="Tutoring Subscription Plan"
                  className="o-mobileNav__navLink"
                >
                  Tutoring Subscription Plan
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="/math-worksheets-store"
                  title="Math Worksheets Store"
                  className="o-mobileNav__navLink"
                >
                  Math Worksheets Store
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="/high-school-tutoring"
                  title="Tutoring Sessions"
                  className="o-mobileNav__navLink"
                >
                  Tutoring Sessions
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/math-worksheets-to-help-students-ace-math.html"
                  title="Why Our Students Ace Math"
                  className="o-mobileNav__navLink"
                >
                  Why Our Students Ace Math
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/curriculum-us"
                  title="Explore Our Curriculum"
                  className="o-mobileNav__navLink"
                >
                  Explore Our Curriculum
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/math-worksheets-by-grade-level.html"
                  title="Math Worksheets By Grade Level for Practice"
                  className="o-mobileNav__navLink"
                >
                  Math Worksheets By Grade Level
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://www.hellothinkster.com/resource-center.html"
                  title="Resources"
                  className="o-mobileNav__navLink"
                >
                  Resources
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://hellothinkster.com/blog"
                  title="Blog"
                  className="o-mobileNav__navLink"
                >
                  Blog
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://www.hellothinkster.com/testimonials.html"
                  title="Parent Reviews"
                  className="o-mobileNav__navLink"
                >
                  Parent Reviews
                </a>
              </li>
            ) : (
              ""
            )}

            {showTopMenu ? (
              <li className="o-mobileNav__navItem">
                <a
                  href="https://www.hellothinkster.com/news/media-reviews/"
                  title="Media Reviews"
                  className="o-mobileNav__navLink"
                >
                  Media Reviews
                </a>
              </li>
            ) : (
              ""
            )}
            <li className="o-mobileNav__navClose">
              <button
                onClick={this.props.onCloseNav}
                className="o-siteHeader__navClose js-navClose"
              >
                <GlyphClose />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
