// NOTE: max-len lint rule is disabled since this page has copy as single-line
// string literals.
/* eslint-disable max-len */
import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import RegistrationTextInput from '../../../RegistrationTextInput';

class LoginForm extends Component {
  state = {
    activeTab: 'sign-up',
  };

  componentDidMount() {
    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { handleSubmit, invalid, submitting } = this.props;

    return (
      <form onSubmit={handleSubmit} id="account-login">
        <div className="b-formBlock">
          <div className="b-formBlock__set">
            <div className="b-formBlock__item">
              <Field
                component={RegistrationTextInput}
                name="lemail"
                placeholder="Email Address"
                type="email"
              />
            </div>
          </div>
          <div className="b-formBlock__set">
            <div className="b-formBlock__item">
              <Field
                component={RegistrationTextInput}
                name="password"
                placeholder="Password"
                type="password"
              />
            </div>
          </div>
        </div>
        <footer className="b-formFooter">
          <button
            disabled={invalid || submitting}
            className="b-button b-button--med b-button--active3 b-blocker__trigger" type="submit" title="Next"
          >
            <span className="b-button__label">Log In</span>
          </button>
        </footer>
      </form>
    );
  }
}

export const formName = 'LoginForm';

export default reduxForm({
  destroyOnUnmount: false,
  enableReinitialize: true,
  form: formName,
})(LoginForm);
