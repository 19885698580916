import { SET_FACEBOOK_AUTH_TOKEN } from '../actions/setFacebookAuthToken';



export default function facebookAuthToken(
  state = null,
  action
) {
  switch (action.type) {
    case SET_FACEBOOK_AUTH_TOKEN:
      return action.payload;

    default:
      return state;
  }
}
