import { put, takeEvery } from 'redux-saga/effects'
import { SELECT_SERVICE } from '../actions/selectService';
import errorAction from '../actions/error';

function* selectNewService(action) {
  try {
    yield put({ type: 'SERVICE_SELECTED', payload: action.payload });
  } catch (error) {
    yield put(errorAction({
      error,
      title: 'Error Setting Service',
    }));
  }
}

export default function* selectServiceLevel() {
  yield takeEvery(SELECT_SERVICE, selectNewService);
}

