import { SET_ANALYTICS_DATA } from '../actions/analytics';

// type GoogleAuthTokenState = ?string;

export default function analytics(
  state = null,
  action
) {
  switch (action.type) {
    case SET_ANALYTICS_DATA:
      return {...state, ...action.payload};

    default:
      return state;
  }
}