import setReferralCodeInputValueMetadata from '../../../../actions/setReferralCodeInputValueMetadata';
import setParentInformationFormErrors from '../../../../actions/setParentInformationFormErrors';
import validateEmailAsync from '../../../../utils/validateEmailAsync';
import validateEmail from '../../../../utils/validateEmail';
import validateReferralCodeAsync from '../../../../utils/validateReferralCodeAsync';
import { selectBillingCycle } from '../../../../actions/selectBillingCycle';

export default function validateParentInformationFormAsync(values, dispatch) {
  let errors = {};
  function resetReferralCodeInputValueMetadata() {
    dispatch(setReferralCodeInputValueMetadata({}));
  }
  const validateEmailPromise = validateEmailAsync(values.email)
    .then(() => {
      
      dispatch(setParentInformationFormErrors(false));
    }).catch((err) => {
      if (err && err.response && err.response.data && err.response.data.message && err.response.data.message === "User Already Exist" ) {
        if (validateEmail(values.email)) {
          dispatch(setParentInformationFormErrors(true));
        }
        errors = {
          ...errors,
          email: 'That email is taken',
        };
      } else {
        dispatch(setParentInformationFormErrors(false));
      }
    });
  const validateReferralCodePromise = values.referralCode
    ? validateReferralCodeAsync(values.referralCode)
        .then((referralCodeInputValueMetadataResult) => {

          dispatch(setReferralCodeInputValueMetadata(referralCodeInputValueMetadataResult));

          if (referralCodeInputValueMetadataResult
            && referralCodeInputValueMetadataResult.code
            && referralCodeInputValueMetadataResult.code.toLowerCase() === 'corp2018') {
            dispatch(selectBillingCycle(6));
          } else if (referralCodeInputValueMetadataResult
            && referralCodeInputValueMetadataResult.code
            && referralCodeInputValueMetadataResult.code.toLowerCase() === 'holidays18') {
            dispatch(selectBillingCycle(3));
          } else if (referralCodeInputValueMetadataResult
            && referralCodeInputValueMetadataResult.code
            && referralCodeInputValueMetadataResult.code.toLowerCase() === 'learn20') { // hardcoding this for nowas we will be moving this to parent app
            dispatch(selectBillingCycle(3));
          }

          // Billing Frequency
          if (referralCodeInputValueMetadataResult && referralCodeInputValueMetadataResult.code
            && referralCodeInputValueMetadataResult.billingFrequency === '3') {
            dispatch(selectBillingCycle(3));
          }
        })
        .catch(() => {
          resetReferralCodeInputValueMetadata();
          errors = {
            ...errors,
            referralCode: 'Invalid referral code',
          };
        })
    : resetReferralCodeInputValueMetadata();

  return Promise.all([
    validateEmailPromise,
    validateReferralCodePromise,
  ])
  .then(() => {
    if (Object.keys(errors).length) {
      throw errors;
    }
  });
}
