import _ from 'lodash';

const selectedService = (state = [], action) => {
  switch (action.type) {
    case 'SERVICE_SELECTED':
      const newlySelected = action.payload;
      newlySelected.id = Math.random().toString(36).slice(-4);
      return [
        newlySelected,
      ];
    case 'SERVICE_REMOVED':
      return _.remove(state, (selectedService) => selectedService.id !== action.payload);
    default:
      return state;
  }
};
export default selectedService;