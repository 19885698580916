import React, { Component } from "react";
import {Helmet} from "react-helmet";
import Header from "../Shared/Header";
import MobileNav from "../Shared/MobileNav";
import Footer from "../Shared/Footer";
import Intercom from "react-intercom";
import { withRouter } from "react-router";

class App extends Component {
  state = { showNav: false };

  onShowNav= () => {
    this.setState({ showNav: true });
  }

  onCloseNav = () => {
    this.setState({ showNav: false });
  }

  render() {
    const { children } = this.props;
    const { location, history } = this.props;
    if (!location || !history) return null;
    let requestPath = location.pathname;
    let iPadPricingPage =
      requestPath.indexOf("/pricing-math-programs-ipad") !== -1;
    let applyStickyHeader = false;
    if (
      requestPath.indexOf("/pricing-math-programs") !== -1 ||
      requestPath.indexOf("/math-worksheets-store") !== -1
    ) {
      applyStickyHeader = true;
    }
    let showFreeTrialBtn = false;
    if (requestPath.indexOf("/pricing-math-programs") !== -1) {
      showFreeTrialBtn = true;
    }
    return (
      <div className="app">
        <Intercom appID="ab72j9ke" />
        <Helmet
          link={[
            {
              href:
                "//fonts.googleapis.com/css?family=Raleway:800,800i|Work+Sans:400,500,600,700",
              rel: "stylesheet"
            },
            {
              href: "//www.hellothinkster.com/favicon.ico",
              rel: "icon",
              type: "image/x-icon"
            },
            {
              href:
                "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css",
              rel: "stylesheet"
            }
          ]}
          title="Thinkster Math Programs"
          meta={[
            { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no" }
          ]}
          script={[{ src: "https://cdn.ravenjs.com/3.8.0/raven.min.js" }]}
        />
        {/*hiding header & footer for the pricing-math-programs-ipad - iPad specific page */}
        {iPadPricingPage ? (
          ""
        ) : (
          <Header
            onShowNav={this.onShowNav}
            stickyHeader={applyStickyHeader}
            trialBtn={showFreeTrialBtn}
            location={location}
            history={history}
          />
        )}
        <MobileNav onCloseNav={this.onCloseNav} showNav={this.state.showNav} />
        <div
          className="app__contents modified--app__contents"
          style={{ overflow: "hidden" }}
        >
          {children}
        </div>
        {iPadPricingPage ? "" : <Footer />}
      </div>
    );
  }
}

export default  withRouter(App)
