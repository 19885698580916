import { SET_GOOGLE_AUTH_TOKEN } from '../actions/setGoogleAuthToken';

// type GoogleAuthTokenState = ?string;

export default function googleAuthToken(
  state = null,
  action
) {
  switch (action.type) {
    case SET_GOOGLE_AUTH_TOKEN:
      return action.payload;

    default:
      return state;
  }
}
