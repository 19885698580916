import { PAYMENT_ERROR, PAYMENT_REQUESTING, PAYMENT_SUCCESS,
         PAYPAL_ERROR, PAYPAL_REQUESTING, PAYPAL_SUCCESS,
         GOOGLE_PAY_REQUESTING,
         GOOGLE_PAY_ERROR,
         GOOGLE_PAY_SUCCESS, 
         APPLE_PAY_REQUESTING,
         APPLE_PAY_ERROR,
         APPLE_PAY_SUCCESS,
        } from '../actions/payment';

const defaultState = {
  error: null,
  paypalError: null,
  creditCards: null,
  requesting: false,
  paypalRequesting: false,
  paypals: null,

  googlePayError: null,
  googlePayRequesting: null,
  googlePay: null,

  applePayError: null,
  applePayRequesting: null,
  applePay: null,
};

export default function payment(
  state = defaultState,
  action
) {
  switch (action.type) {
    case PAYMENT_ERROR:
      return { ...state, error: action.payload, requesting: false };

    case PAYMENT_REQUESTING:
      return { ...state, error: null, requesting: true };

    case PAYMENT_SUCCESS:
      return { ...state, creditCards: action.payload, error: null, requesting: false };

    case PAYPAL_ERROR:
      return { ...state, paypalError: action.payload, paypalRequesting: false };

    case PAYPAL_REQUESTING:
      return { ...state, paypalError: null, paypalRequesting: true };

    case PAYPAL_SUCCESS:
      return { ...state, paypals: action.payload, error: null, paypalRequesting: false };

    case GOOGLE_PAY_ERROR:
      return { ...state, googlePayError: action.payload, googlePayRequesting: false };

    case GOOGLE_PAY_REQUESTING:
      return { ...state, googlePayError: null, googlePayRequesting: true };

    case GOOGLE_PAY_SUCCESS:
      return { ...state, googlePay: action.payload, error: null, googlePayRequesting: false };

    case APPLE_PAY_ERROR:
      return { ...state, applePayError: action.payload, applePayRequesting: false };

    case APPLE_PAY_REQUESTING:
      return { ...state, applePayError: null, applePayRequesting: true };

    case APPLE_PAY_SUCCESS:
      return { ...state, applePay: action.payload, error: null, applePayRequesting: false };



    default:
      return state;
  }
}
