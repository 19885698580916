/* eslint-disable no-constant-condition */
import { put, take, select } from 'redux-saga/effects';
import axios from 'axios';
import _ from 'lodash';
import { SET_GEO_LOCATION } from '../actions/setGeoLocation';
import setPlans from '../actions/setPlans';
import selectCode from '../selectors/code';
import errorAction from '../actions/error';
// import selectedExperiment from '../selectors/experiments';
import { selectService } from '../actions/selectService';
import { setExperiment } from '../actions/experiments';
import { setMostPopularPlan } from '../actions/mostPopularPlan';
import { setDefaultTrialPlan } from '../actions/defaultTrialPlan';
import {setDefaultBillingCycle} from '../actions/defaultBillingCycle';
import { selectBillingCycle } from '../actions/selectBillingCycle'

const cbBaseURL = process.env.REACT_APP_CB_API_BASE_URL || 'https://chargebee-api.hellothinkster.com/api';
// const defaultCountryCode = process.env.REACT_APP_DEFAULT_COUNTRY_CODE || 'US';

export default function* fetchPlansSaga() {
  
  while (true) {
    yield take(SET_GEO_LOCATION);
    // const geoLocation = action.payload;
    const countryCode = "US"; // geoLocation.country_code || defaultCountryCode;
    const code = yield select(selectCode);
    let expId = 'Alps2022';
    if (window && window.location) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('utm_expid')) {
        expId = params.get('utm_expid');
      }
    } 
    yield put(setExperiment(expId));
    let url = '';
    if (expId) {
      url = `/get_cb_plans?country=${countryCode}&exp_id=${expId}&with_session_plans=true`;
    } else {
      url = `/get_cb_plans?country=${countryCode}&with_session_plans=true`;
    }

    if (code !== null) {
      url += `&code=${code}`;
    }
    try {
      const plansResult = yield axios({
        method: 'get',
        baseURL: cbBaseURL,
        url,
      });
      let plans = plansResult.data.plans;
      plans = plans.filter(p => p.cf_financing !== 'True' && p.cf_financing !== 'TRUE')
      const groupedPlans = _.groupBy(plans, 'cf_course');
      plans = _.mapValues(groupedPlans, (groupedPlan) => {
        const plansGroupedByServiceId = _.groupBy(groupedPlan, 'cf_service_id');
        Object.keys(plansGroupedByServiceId).map((serviceId) => {
          const plansGroupByFreq = _.groupBy(plansGroupedByServiceId[serviceId], 'period');
          _.map(plansGroupByFreq, (plan, duration) => {
            plansGroupByFreq[duration] = plan[0];
          });
          plansGroupedByServiceId[serviceId] = plansGroupByFreq;
          return null;
        });
        return plansGroupedByServiceId;
      });
      yield put(setPlans(plans));
      const experiment = plansResult.data.experiment;
      const defaultTrialPlan = experiment && experiment.default_trial_plan ? experiment.default_trial_plan : 'Platinum';
      yield put(selectService({selectedService: defaultTrialPlan}))
      
      let defaultTrialPlanName = experiment && experiment.default_trial_plan ?  experiment.default_trial_plan : 'Platinum'
      yield put(setDefaultTrialPlan(defaultTrialPlanName));

      const mostPopularPlanName = experiment && experiment.most_popular_plan ? experiment.most_popular_plan : 'Platinum';
      yield put(setMostPopularPlan(mostPopularPlanName))

      const defaultBillingCycle = experiment && experiment.default_billing_cycle ? experiment.default_billing_cycle : 12;
      yield put(setDefaultBillingCycle(defaultBillingCycle));
      yield put(selectBillingCycle(defaultBillingCycle));      
    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error fetching grade options',
      }));
    }
  }
}