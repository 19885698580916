/* eslint-disable no-constant-condition */
import { put, take } from 'redux-saga/effects';
import axios from 'axios';
import { SET_GEO_LOCATION } from '../actions/setGeoLocation';
import setGradeOptions from '../actions/setGradeOptions';
import errorAction from '../actions/error';
import { setGradeResult } from '../actions/setGradeOptions';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';
const defaultCountryCode = process.env.REACT_APP_DEFAULT_COUNTRY_CODE || 'US';

export default function* fetchGradeOptionsSaga() {
  while (true) {
    const action = yield take(SET_GEO_LOCATION);
    const geoLocation = action.payload;
    const countryCode = geoLocation.country_code || defaultCountryCode;

    try {
      const gradeOptionsResponse = yield axios({
        method: 'get',
        baseURL,
        url: `/v1/grades/${countryCode}`,
      });
      const gradeOptionsResult = gradeOptionsResponse.data;
      const gradeOptions = gradeOptionsResult && gradeOptionsResult.grades
        ? gradeOptionsResult.grades.map(grade => {
          const gradeKey = Object.keys(grade)[0];
          const gradeValue = grade[gradeKey];

          return {
            key: gradeKey,
            value: gradeValue,
          };
        })
        : [];

      yield put(setGradeOptions(gradeOptions));
      yield put(setGradeResult(gradeOptionsResult));
    } catch (error) {
      yield put(errorAction({
        error,
        title: 'Error fetching grade options',
      }));
    }
  }
}
