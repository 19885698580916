import { SET_GRADE_OPTIONS } from '../actions/setGradeOptions';

const GradeOption = {
  name: '',
  value: '',
};
// type GradeOptionsState = ?GradeOption[];

export default function gradeOptions(
  state = GradeOption,
  action
) {
  switch (action.type) {
    case SET_GRADE_OPTIONS:
      return action.payload;

    default:
      return state;
  }
}
