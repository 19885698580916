/* eslint-disable no-constant-condition */
import { select, take, put } from 'redux-saga/effects';
import axios from 'axios';
import { getFormValues } from 'redux-form';
import errorAction from '../actions/error';
import loadingAction from '../actions/loading';
import setBraintreeClientToken from '../actions/setBraintreeClientToken';
import setSession from '../actions/setSession';
import routerHistory from '../constants/routerHistory';
import { formName as loginFormName } from '../components/StoreAccountInfo/components/AccountInfo/LoginForm';
import storeCart from '../selectors/storeCart';
import { LOGIN, loginFailed } from '../actions/login';
import { storeCartAddDiscount } from '../actions/storeCart';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://enroll-api.hellothinkster.com';

export default function* login() {
  while (true) {
    yield take(LOGIN);
    const parentInformationFormValues = yield select(getFormValues(loginFormName));
    if (window && window.dataLayer) {
      // NOTE: window.dataLayer is an observed array that must be mutated...
      window.dataLayer.push({ email: parentInformationFormValues.email });
      window.dataLayer.push({ zip: parentInformationFormValues.postalCode });
    }

    try {
      yield put(loadingAction(true));

      const createSessionResponse = yield axios({
        method: 'post',
        baseURL,
        url: '/v1/sessions',
        data: {
          username: parentInformationFormValues.lemail,
          password: parentInformationFormValues.password,
        },
      });
      const createSessionResult = createSessionResponse.data;
      const sessionToken = createSessionResult.token;
      const sessionUserId = createSessionResult.user_id;

      // if (typeof _paq !== 'undefined') {
      //   _paq.push(['setUserId', sessionUserId]);
      // }

      if (window && window.dataLayer) {
        // NOTE: window.dataLayer is an observed array that must be mutated...
        window.dataLayer.push({
          event: 'authentication',
          authType: 'Sign In',
          uid: sessionUserId,
        });
      }

      // Set app session...
      yield put(setSession({
        token: sessionToken,
        userId: sessionUserId,
      }));

      // Fetch braintree client token
      try {
          const braintreeClientTokenResponse = yield axios({
            method: 'post',
            baseURL,
            url: '/v1/paymentmethods/client_token',
            headers: { Authorization: `JWT ${sessionToken}` },
            data: { userId: sessionUserId },
          });
          const braintreeClientTokenResult = braintreeClientTokenResponse.data;
          const braintreeClientToken = braintreeClientTokenResult.clientToken;
          // Set app braintree client token...
          yield put(setBraintreeClientToken(braintreeClientToken));

      } catch (error) {
        // ...
      }

      //Fetch Parent Status to check
      try {
          const userStatusResponse = yield axios({
            method: 'get',
            baseURL,
            url: `/v1/parents/${sessionUserId}/status`,
            headers: { Authorization: `JWT ${sessionToken}` },
          });
          let applyDiscount = false;
          const userStatusResult = userStatusResponse.data;
          if (userStatusResult && userStatusResult.students) {
            userStatusResult.students.map((student) => {
                if (student.isPaid && student.is_active) {
                  applyDiscount = true;
                }
                return null;
            })
          }

          const cart = yield select(storeCart);
          let purchaseCategory = 'Math';
          let productName = '';
          if (cart.items.length > 0 && cart.items[0]) {
            purchaseCategory = cart.items[0].category;
            productName = cart.items[0].name;
          }
          if (purchaseCategory === 'SummerProgram') {
            yield put(storeCartAddDiscount({discount: 20, type: 'percentage'})); //25% Discount
          } else if (purchaseCategory === 'Math') {
            // 25% discount is added to all existing users
            if (applyDiscount && productName !== 'Art of Word Problems') {
              yield put(storeCartAddDiscount({discount: 25, type: 'percentage'})); //25% Discount
            } else {
              yield put(storeCartAddDiscount({discount: 0, type: 'percentage'})); //remove discount
            }
          } else if (purchaseCategory === 'TutoringSession'){
            //Don;t do anything
          } else {
            yield put(storeCartAddDiscount({discount: 0, type: 'percentage'})); //No Discount
          }
      } catch (error) {
        // ...
      }


      yield put(loadingAction(false));
      let path = '/step-3-store';
      routerHistory.push({
        pathname: path,
        search: window.location.search,
      });

    } catch (error) {
      yield put(loadingAction(false));
      yield put(errorAction({
        error,
        title: 'Error Login',
      }));
      yield put(loginFailed({ error: true }));
    }
  }
}
