import { put, takeEvery } from 'redux-saga/effects';
import { SELECT_BILLING_CYCLE } from '../actions/selectBillingCycle';
import errorAction from '../actions/error';

function* selectNewBillingCycle(action) {
  try {
    yield put({ type: 'BILLING_CYCLE_SELECTED', payload: action.payload });
  } catch (error) {
    yield put(errorAction({
      error,
      title: 'Error Setting Billing Cycle',
    }));
  }
}

export default function* selectBillingCycle() {
  yield takeEvery(SELECT_BILLING_CYCLE, selectNewBillingCycle);
}
