import { SET_SESSION } from '../actions/setSession';
import { LOGIN_FAILED } from '../actions/login';

export default function session(
  state = { token: null, userId: null, error: null },
  action
) {
  switch (action.type) {
    case SET_SESSION:
      return action.payload;

    case LOGIN_FAILED:
      state.token = null;
      state.error = true;
      return { ...state };
    default:
      return state;
  }
}
