import { SET_CODE } from '../actions/setCode';

export default function code(
  state = null,
  action
) {
  switch (action.type) {
    case SET_CODE:
      return action.payload;
    default:
      return state;
  }
}
