/* eslint-disable global-require */
// NOTE: Global `require` is used because style imports are conditional.
import isClient from '../../constants/isClient';


// (Only import styles on client...)
if (isClient) {
  // NOTE: Import styles here.
  // NOTE: Be aware that order of imports determines precedence.
  // require('react-telephone-input/css/default.css'); // (react-tel-input styles)
  require('react-block-ui/style.css');
  require('react-tabs/style/react-tabs.css');
  require('./dropin.css');
  require('./global.css');
}