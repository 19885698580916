import _ from 'lodash';
import { ADD_STUDENT, REMOVE_STUDENT, UPDATE_STUDENT, CLEAR_STUDENTS } from '../actions/students';

export default function plans(
  state = [],
  action
) {
  switch (action.type) {
    case ADD_STUDENT:
      const newStudent = action.payload;
      newStudent.id = Math.random().toString(36).slice(-4);
      return [
        ...state,
        newStudent,
      ];

    case UPDATE_STUDENT:
      const updatedStudent = action.payload;
      const updatedStudents = [];
      state.map((student) => {
        if (student.id === updatedStudent.id) {
          return updatedStudents.push(updatedStudent);
        } else {
          return updatedStudents.push(student);
        }
      });
      return updatedStudents;
    case REMOVE_STUDENT:
      return _.remove(state, (student) => student.id !== action.payload);
    case CLEAR_STUDENTS: 
      return [];
    default:
      return state;
  }
}
