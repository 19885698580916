import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import geoLocation from './geoLocation';
import facebookAuthToken from './facebookAuthToken';
import googleAuthToken from './googleAuthToken';
import gradeOptions from './gradeOptions';
import parentInformationFormInitialValues from './parentInformationFormInitialValues';
import parentInformationFormErrors from './parentInformationFormErrors';
import referralCodeInputValueMetadata from './referralCodeInputValueMetadata';
import plans from './plans';
import selectedPlans from './selectedPlans';
import selectedService from './selectedService';
import totals from './totals';
import cart from './cart';
import session from './session';
import loading from './loading';
import payment from './payment';
import braintreeClientToken from './braintreeClientToken';
import parentInfo from './parentInfo';
import studentInfo from './studentInfo';
import code from './code';
import students from './students';
import selectedBillingCycle from './selectedBillingCycle';
import products from './products';
import storeCart from './storeCart';
import serviceLevels from './serviceLevels';
import nonce from './nonce';
import selectedExperiment from './selectedExperiment';
import urlParameter from './urlParameter';
import selectedCTA from './selectedCTA';
import gradeResult from './gradeResult';
import mostPopularPlan from './mostPopularPlan';
import defaultTrialPlan from './defaultTrialPlan';
import subExperiment from './subExperiment';
import defaultBillingCycle from './defaultBillingCycle';
import couponCode from './couponCode';
import analytics from './analytics';
import enrollCouponCode from './enrollCouponCode';

export default combineReducers({
  form,
  geoLocation,
  facebookAuthToken,
  googleAuthToken,
  gradeOptions,
  plans,
  totals,
  braintreeClientToken,
  cart,
  session,
  selectedPlans,
  selectedService,
  loading,
  code,
  payment,
  parentInfo,
  studentInfo,
  students,
  parentInformationFormInitialValues,
  parentInformationFormErrors,
  referralCodeInputValueMetadata,
  selectedBillingCycle,
  products,
  storeCart,
  serviceLevels,
  nonce,
  selectedExperiment,
  urlParameter,
  selectedCTA,
  gradeResult,
  mostPopularPlan,
  defaultTrialPlan,
  subExperiment,
  defaultBillingCycle,
  couponCode,
  analytics,
  enrollCouponCode
});
