import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import isClient from './constants/isClient';
import store from './constants/store';
import './assets/sass';
import './assets/css';
import requestJSONP from './utils/requestJSONP';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Router } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import history from "./constants/routerHistory";

if (isClient) {
  let host = window.location.hostname;
  if (host.indexOf('localhost') === -1 && host.indexOf('sandbox') === -1) {
    const freeGeoIPJSONURL = process.env.FREE_GEO_IP_JSON_URL || 'https://api.ipstack.com/check?access_key=566719b19a463dd643e82d2301e777d3&output=json&legacy=1';
    requestJSONP(freeGeoIPJSONURL)
    .then((responseJson) => {
      if (responseJson && responseJson.country_code && responseJson.country_code === 'IN') {
        if (typeof window !== 'undefined') {
          window.location.href = "https://enroll.hellothinkster.co.in";
        }
      }
      // if (responseJson && responseJson.country_code && responseJson.country_code === 'GB') {
      //   if (typeof window !== 'undefined') {
      //     window.location.href = "https://enroll.hellothinkster.co.uk";
      //   }
      // }
    })
    .catch((error) => {
    });
  }
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <HttpsRedirect>
          <Router history={history}>
            <App />
          </Router>
        </HttpsRedirect>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
