// NOTE: max-len lint rule is disabled since this page has copy as single-line
// string literals.
/* eslint-disable max-len */
import React, { Component } from 'react';

export default class ThinksterLogomark extends Component {
  render() {
    return (
      <svg className="o-thinkster__logomark" width="49" height="48" viewBox="0 0 49 48" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd"><path d="M21.245 24c-4.224-3.49-9.52-5.424-15-5.475 7.024-.12 12.69-5.786 12.81-12.81.05 5.48 1.984 10.776 5.475 15-.987 1.198-2.087 2.298-3.285 3.285" fill="#ACD6F2"/><path d="M27.755 24c-1.186-.98-2.276-2.07-3.255-3.255 3.49-4.224 5.424-9.52 5.475-15 .12 7.02 5.776 12.682 12.795 12.81-5.48.05-10.776 1.984-15 5.475" fill="#D7E940"/><path d="M19.025 42.285c-.12-7.024-5.786-12.69-12.81-12.81 5.48-.05 10.776-1.984 15-5.475 1.197.977 2.297 2.067 3.285 3.255-3.49 4.224-5.424 9.52-5.475 15" fill="#FFB0DF"/><path d="M5.975 29.475c-3.028 0-5.482-2.455-5.482-5.483 0-3.027 2.454-5.482 5.482-5.482H6.2c5.48.05 10.776 1.984 15 5.475-4.224 3.49-9.52 5.424-15 5.475h-.225" fill="#AC83EA"/><path d="M24.5 20.745c-3.49-4.224-5.424-9.52-5.475-15v-.18c0-3.028 2.455-5.483 5.483-5.483 3.027 0 5.482 2.455 5.482 5.483v.165c-.05 5.48-1.984 10.776-5.475 15" fill="#7CC714"/><path d="M24.5 48c-3.024 0-5.475-2.45-5.475-5.475v-.165c.05-5.48 1.984-10.776 5.475-15 3.49 4.224 5.424 9.52 5.475 15v.165c0 3.024-2.45 5.475-5.475 5.475" fill="#FD7F20"/><path d="M43.025 29.475H42.8c-5.48-.05-10.776-1.984-15-5.475 4.224-3.49 9.52-5.424 15-5.475h.24c3.028 0 5.483 2.455 5.483 5.483 0 3.027-2.455 5.482-5.483 5.482" fill="#D5A809"/><path d="M29.975 42.3c-.05-5.48-1.984-10.776-5.475-15 .977-1.202 2.067-2.307 3.255-3.3 4.224 3.49 9.52 5.424 15 5.475-7.024.12-12.69 5.786-12.81 12.81" fill="#FDB824"/></g>
      </svg>
    );
  }
}
