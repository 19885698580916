import _ from 'lodash';

const selectedPlans = (state = [], action) => {
  switch (action.type) {
    case 'PLAN_SELECTED':
      const newlySelected = action.payload;
      newlySelected.id = Math.random().toString(36).slice(-4);
      return [
        ...state,
        newlySelected,
      ];
    case 'PLAN_REMOVED':
      return _.remove(state, (plan) => plan.id !== action.payload);
    default:
      return state;
  }
};
export default selectedPlans;